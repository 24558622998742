@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --cl-black: #00000f;
  --cl-black-rgb: 0 0 15;

  --cl-blue: #80f9fa;
  --cl-blue-rgb: 128 249 250;

  --cl-dark-blue: #00085f;
  --cl-dark-blue-rgb: 0 8 95;

  --cl-white: #f5f5f5;
  --cl-white-rgb: 245 245 245;

  --cl-light-grey: #e4ecf1;

  --cl-medium-grey: #8498a0;

  --cl-grey: #606470;
  --cl-grey-rgb: 160 174 192;

  --cl-dark-grey: #4f4c4c;
  --cl-dark-grey-rgb: 79 76 76;

  --cl-red: #e20000;
  --cl-red-rgb: 239 68 68;

  --cl-yellow: #f0ce47;
  --cl-yellow-rgb: 240 206 71;

  --cl-purple: #8038ad;
  --cl-purple-rgb: 128 56 173;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --gradient: linear-gradient(90deg, #80f9fa 0%, #00085f 100%);

  --font-main: 'Outfit', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 12000;
  --mobile-menu-end-point: 12000;

  --vh: calc(100vh / 100);
}

/*!* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same *!
@custom-media --mobile-menu-start-point (max-width: 1200px);

!* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point *!
@custom-media --mobile-menu-end-point (min-width: 1201px);*/

@define-mixin mobile-menu-start-point {
  @media not all and (min-width: 12000px) {
    @mixin-content;
  }
}

@define-mixin mobile-menu-end-point {
  @media (min-width: 12000px) {
    @mixin-content;
  }
}
