.pagination {
  margin-top: ac(70px, 40px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: ac(10px, 6px);
  }

  i {
    font-size: 20px;
  }

  &__item {
    font-family: var(--font-main);
    font-size: ac(16px, 14px);
    font-weight: 400;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    /*width: ac(30px, 30px);
    height: ac(30px, 30px);*/
    padding-left: 6px;
    padding-right: 6px;
    /*border: 2px solid transparent;*/
    transition: all 0.25s ease;
    cursor: pointer;
    opacity: 0.5;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    a,
    span {
      font: inherit;
    }

    &.active {
      /*border-color: var(--cl-dark-grey);*/
      cursor: not-allowed !important;
      opacity: 1;
    }

    &:not(.active) {
      &:hover {
        border-color: var(--cl-light-grey);
        opacity: 0.7;
      }
    }

    &.with-points {
      pointer-events: none;
      margin-left: ac(-10px, -6px);
      margin-right: ac(-10px, -6px);

      .pagination__num {
        transform: translateY(4px);
      }
    }
  }

  &__item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    width: ac(35px, 34px);
    height: ac(35px, 34px);
    background-color: var(--cl-white);
    transition: background-color 0.3s ease, opacity 0.3s ease;

    i {
      font-size: 18px;
      color: var(--cl-black);
      transition: transform 0.3s ease, color 0.3s ease;
    }

    &:hover {
      background-color: var(--cl-blue);
      color: var(--cl-dark-blue);
    }

    &.prev {
      border-radius: 30px 6px 6px 30px;
      margin-right: 10px;
    }

    &.next {
      border-radius: 6px 30px 30px 6px;
      margin-left: 10px;
    }
  }
}
