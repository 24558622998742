.offer-section {
  padding: 100px 0;
  overflow: hidden;
  max-width: 1940px;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}
.offer-swiper-wr {
}
.offer-swiper {
}
