.progression-section {
  padding-top: ac(52px, 30px);
  padding-bottom: 5px;
  overflow: hidden;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    min-height: ac(784px, 440px);
  }

  &__container {
    @mixin md {
      @mixin aspect-ratio-block 1984, 784;
      position: absolute;
      top: 0;
      left: ac(-105px, -45px);
      width: 155.49%;
      overflow: visible;
    }

    @mixin max-md {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      z-index: 1;
    }
  }

  &__line {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top left;

      &.with-mob {
        display: none;
      }
    }

    @mixin max-md {
      @mixin aspect-ratio 1984, 164;
      position: absolute;
      left: 0;
      top: 0;
      width: 200px;
      z-index: -1;

      img {
        object-fit: contain;
        object-position: top left;

        display: none;

        &.with-mob {
          display: block;
        }
      }
    }
  }

  &__items {
    @mixin md {
      position: absolute;
      inset: 0;
    }

    @mixin max-md {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__item {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:nth-child(1) {
      @mixin md {
        left: 5.29%;
        top: 41.96%;

        .progression-section__item-content {
          top: -100px;
          margin-top: 18px;
          /*padding-top: 108px;*/
          margin-left: ac(48px, 20px);
          width: calc(ac(304px, 290px));
        }

        .progression-section__item-title-container {
          width: 100%;
          min-width: ac(412px, 290px);
          transform: translateX(79%);

          @mixin xl {
            transform: translateX(79%) translateY(14%);
          }
        }

        .progression-section__item-title {
          max-width: ac(370px, 264px);
        }

        .line-decor.animated::before {
          width: ac(258px, 100px);
        }
      }

      @mixin max-md {
        margin-top: 20px;
        left: -5px;
      }
    }

    &:nth-child(2) {
      @mixin md {
        left: 16.18%;
        top: 0;

        &:before {
          transform: rotate(90deg);
          top: ac(60px, 55px);
          left: ac(23px, 20px);
          transform-origin: left;
        }

        .progression-section__item-content {
          padding-top: 80px;
          width: ac(304px, 290px);
          left: ac(-40px, 0px);
        }

        .progression-section__item-title-container {
          width: 100%;
          min-width: ac(412px, 290px);
          transform: translateX(80%);
        }

        .progression-section__item-title {
          max-width: ac(370px, 264px);
        }

        .line-decor.animated::before {
          width: ac(258px, 100px);
        }
      }

      @mixin max-md {
        margin-left: -5px;

        .progression-section__item-content {
          left: 45px;
          transform: translateY(-20%);
        }
      }
    }

    &:nth-child(3) {
      @mixin md {
        left: 27.07%;
        top: 4.97%;

        &:before {
          transform: rotate(90deg);
          top: ac(60px, 55px);
          left: ac(23px, 20px);
          transform-origin: left;
        }

        .progression-section__item-content {
          padding-top: 40px;
          width: ac(304px, 290px);
          left: ac(-50px, 0px);
        }

        .progression-section__item-title-container {
          width: 100%;
          min-width: ac(412px, 290px);
          transform: translateX(70%);

          @mixin max-xl {
            transform: translateX(50%);
          }
        }

        .progression-section__item-title {
          max-width: ac(370px, 264px);
        }

        .line-decor.animated::before {
          width: ac(258px, 100px);
        }
      }

      @mixin max-md {
        margin-left: -5px;

        .progression-section__item-content {
          left: 50px;

          @mixin xs {
            left: 55px;
          }
        }
      }
    }

    &:nth-child(4) {
      @mixin md {
        left: 37.95%;
        top: 72.32%;

        &:before {
          transform: rotate(-90deg) scale(1, -1);
          top: ac(-12px, -8px);
          left: ac(23px, 20px);
          transform-origin: left;
        }

        .progression-section__item-content {
          padding-top: 0;
          width: ac(304px, 290px);
          padding-bottom: ac(80px, 40px);
          transform: translateY(-100%);
          left: ac(-100px, -50px);
        }

        .progression-section__item-title-container {
          width: 100%;
          min-width: ac(412px, 290px);
          transform: translateX(70%);

          @mixin max-xl {
            transform: translateX(30%);
          }
        }

        .progression-section__item-title {
          max-width: ac(370px, 264px);
        }

        .line-decor.animated::before {
          width: ac(258px, 100px);
        }
      }

      @mixin max-md {
        margin-left: 25px;

        .progression-section__item-content {
          left: 40px;

          @mixin xs {
            left: 45px;
          }
        }
      }
    }

    &:nth-child(5) {
      @mixin md {
        left: 48.84%;
        top: -24px;

        &:before {
          transform: rotate(90deg);
          top: ac(60px, 55px);
          left: ac(23px, 20px);
          transform-origin: left;
        }

        .progression-section__item-content {
          padding-top: ac(120px, 80px);
          width: ac(304px, 290px);
          left: ac(-140px, -100px);
        }

        .progression-section__item-title-container {
          width: 100%;
          min-width: ac(412px, 290px);
          transform: translateX(-25%);
        }

        .progression-section__item-title {
          max-width: ac(370px, 264px);
        }

        .line-decor.animated::before {
          width: ac(258px, 100px);
        }
      }

      @mixin max-md {
        margin-left: 25px;

        .progression-section__item-content {
          left: 40px;
          transform: translateY(-40%);
        }
      }
    }

    &:nth-child(6) {
      @mixin md {
        left: 59.73%;
        top: 74.11%;

        &:before {
          transform: rotate(-90deg) scale(1, -1);
          top: ac(-12px, -8px);
          left: ac(23px, 20px);
          transform-origin: left;
        }

        .progression-section__item-content {
          padding-top: 0;
          width: ac(304px, 290px);
          padding-bottom: ac(80px, 40px);
          transform: translateY(-100%) translateX(-100%);
        }

        .progression-section__item-title-container {
          width: 100%;
          min-width: ac(412px, 290px);
          transform: translateX(-50%);

          @mixin max-lg {
            transform: translateX(-25%);
          }
        }

        .progression-section__item-title {
          max-width: ac(370px, 264px);
        }

        .line-decor.animated::before {
          width: ac(258px, 100px);
        }
      }

      @mixin max-md {
        left: 25px;

        .progression-section__item-content {
          left: 40px;
          transform: translateY(-80%);
        }
      }
    }

    @mixin max-md {
      position: relative;
      margin-bottom: 100px;

      .progression-section__item-content {
        left: 35px;
        transform: translateY(-10%);
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: ac(63px, 50px);
      top: ac(23px, 20px);
      height: 1px;
      width: 0;
      background: var(--cl-white);
      opacity: 0;
      transition: all 0.3s ease;
    }

    &.active {
      &:before {
        width: ac(45px, 15px);
        opacity: 1;
      }
    }
  }

  &__item-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(46px, 40px);
    height: ac(46px, 40px);
    border-radius: 50%;
    background-color: var(--cl-dark-blue);
    color: var(--cl-white);
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    position: relative;
    z-index: 10;

    &.active {
      background: var(--cl-blue);
      color: var(--cl-black);

      span {
        opacity: 0;
      }

      i {
        opacity: 1;
      }
    }

    span {
      transition: all 0.3s ease;
      font-family: var(--font-main);
      font-size: ac(30px, 20px);
      line-height: normal;
      font-weight: 500;
      opacity: 1;
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      font-size: ac(20px, 14px);
      transition: all 0.3s ease;
      opacity: 0;
    }
  }

  &__item-content {
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: ac(304px, 290px);
    top: 0;
    left: 60px;

    &.active {
      display: flex;
    }

    @mixin max-md {
      width: 80vw;
      padding-left: 35px;

      .progression-section__item-title {
        max-width: 220px;
      }

      .progression-section__item-title-container {
        width: 100%;
      }

      .line-decor.animated::before {
        width: 140px;
      }
    }

    @mixin max-sm {
      width: 70vw;

      .line-decor.animated::before {
        width: 120px;
      }
    }

    @mixin media 421 {
      width: 66vw;
    }

    @mixin max-xs {
      width: 64vw;

      .progression-section__item-title-container {
        margin-bottom: 15px;
      }

      .progression-section__item-title {
        max-width: 170px;
        font-size: 23px;
      }

      .line-decor.animated::before {
        width: 100px;
      }

      .line-decor {
        margin-bottom: 11px;
      }
    }

    @mixin media 330 {
      .line-decor.animated::before {
        width: 90px;
      }
    }
  }

  &__item-text {
    width: 100%;
    max-height: 306px;
    padding-right: ac(0px, 10px);

    p,
    li {
      font-size: ac(16px, 14px);
    }

    .simplebar-track.simplebar-vertical {
      width: 3px;
      background: var(--cl-dark-blue);
      border-radius: 4px;
      transform: translateX(ac(10px, 0px));

      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    @mixin media 901 {
      max-height: 140px;
    }

    @mixin media 800 {
      max-height: 124px;
    }

    @mixin max-md {
      max-height: 306px;
    }

    @mixin max-xs {
      max-height: 236px;
    }
  }
}
