.btn-link {
  position: relative;
  min-height: 27px;
  display: flex;
  align-items: center;
  padding-left: ac(25px, 34px);
  width: fit-content;
  font-size: ac(18px, 20px);
  font-weight: 600;
  cursor: pointer;
  -webkit-appearance: unset !important;
  text-transform: lowercase;

  span {
    position: relative;
    z-index: 5;
    pointer-events: none;
    white-space: nowrap;

    &:not(.circle) {
      transition: all 0.3s ease;
      color: var(--cl-white);
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
    }
  }

  .circle {
    size: 10px;
    border: 2px solid var(--cl-yellow);
    background-color: var(--cl-yellow);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    transition: all 0.15s ease;

    top: 55%;

    transform: translate(0, -50%) scale(1);
    left: ac(0px, 8px);
  }

  @mixin lg {
    &:hover {
      span:not(.circle) {
        color: var(--cl-yellow);
        /*color: transparent;*/
      }
    }
  }

  @mixin max-lg {
    .circle {
      transition: all 0.3s ease;
    }

    &:hover {
      span:not(.circle) {
        color: var(--cl-yellow);
        /*color: transparent;*/
      }

      .circle {
        background-color: transparent;
      }
    }
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: ac(53px, 50px);
  transition: all 0.4s ease;
  padding-top: ac(12px, 10px);
  padding-bottom: ac(12px, 10px);
  cursor: pointer;
  position: relative;

  &__arrow {
    transition: all 0.5s ease;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 6px 30px 30px 6px;
    background: var(--cl-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(43px, 40px);
    /*transform-origin: left;*/

    i {
      opacity: 0;
      font-size: 16px;
      transition: all 0.5s ease;
      color: var(--cl-black);
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-main);
    color: var(--cl-white);
    line-height: normal;
    transition: all 0.3s ease 0.1s;
  }

  &:hover {
    padding-left: 0;
    padding-right: ac(53px, 50px);

    .btn__arrow {
      left: 100%;
      transform: translateX(-100%);
      /*animation: btnAnimation 0.5s linear forwards;*/

      /*i {
        opacity: 1;
      }*/
    }
  }

  &.btn-dark {
    .btn__arrow {
      background: var(--cl-black);

      i {
        color: var(--cl-white);
      }
    }

    .btn__text {
      color: var(--cl-dark-blue);
    }

    &:hover {
      .btn__arrow {
        background: var(--cl-blue);

        i {
          color: var(--cl-black);
        }
      }
    }
  }
}

@keyframes btnAnimation {
  0% {
    transform: scale(1) translateX(0) translateY(0);
    /* border-radius: 6px 30px 30px 6px;*/
  }
  25% {
    transform: scale(0.5, 0.2) translateX(-50%) translateY(10%);
    /*border-radius: 30% 70% 68% 32% / 30% 49% 51% 70%;*/
  }
  55% {
    transform: scale(0.6, 0.8) translateX(-100%) translateY(10%);
    /*border-radius: 68% 32% 32% 68% / 37% 49% 51% 63%;*/
  }
  80% {
    transform: scale(1) translateX(-100%) translateY(0);
    /*border-radius: 6px 30px 30px 6px;*/
  }
  100% {
    transform: scale(1) translateX(-100%) translateY(0);
    /*border-radius: 6px 30px 30px 6px;*/
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: ac(40px, 30px);
  grid-row-gap: ac(20px, 16px);
}

.slider-btn {
  width: ac(35px, 34px);
  height: ac(35px, 34px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cl-white);
  transition: background-color 0.3s ease, opacity 0.3s ease;
  cursor: pointer;

  i {
    font-size: 18px;
    color: var(--cl-black);
    transition: transform 0.3s ease, color 0.3s ease;
  }

  &.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.swiper-button-disabled) {
      background: var(--cl-blue);
      color: var(--cl-dark-blue);
    }
  }

  &.swiper-button-lock {
    display: none;
  }

  &.prev {
    border-radius: 30px 6px 6px 30px;
  }

  &.next {
    border-radius: 6px 30px 30px 6px;
  }
}

.slider-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(70px, 40px);

  .slider-btn {
    margin-top: ac(50px, 30px);
  }
}

.social-link {
  font-size: ac(14px, 20px);
  cursor: pointer;
  transition: all 0.2s linear;
  color: var(--cl-dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    /*color: var(--cl-purple);*/
    color: var(--cl-dark-blue);
    transform: scale(1.5);
  }

  i.icon-twitter {
    font-size: ac(12px, 18px);
  }

  i.icon-mail {
    font-size: ac(12px, 18px);
  }
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ac(23px, 20px);
}

.border-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 30px 30px 6px;
  border: 1px solid var(--cl-white);
  padding: 5px 30px;
  min-height: 44px;
  min-width: ac(176px, 120px);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: var(--cl-blue);
    color: var(--cl-black);
  }
}

.filters-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  &__content {
    font-size: ac(26px, 24px);
    font-family: var(--font-main);
    font-weight: 500;
    color: var(--cl-white);
  }

  &__icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      font-size: 16px;
      color: var(--cl-white);
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      &.icon-close {
        opacity: 0;
        color: var(--cl-blue);
      }
    }
  }

  &.active {
    .filters-btn__icon {
      i.icon-close {
        opacity: 1;
      }

      i.icon-filters {
        opacity: 0;
      }
    }
  }
}
