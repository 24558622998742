/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: ac(76px, 12px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.scrolled {
    &:not(.open-menu) {
      background: rgba(var(--cl-black), 0.2);
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      padding: ac(20px, 10px) 0;

      &:before {
        opacity: 0.6;
        background-color: var(--cl-black);
      }

      .logo {
        width: ac(150px, 140px);
      }
    }
  }

  .logo {
    height: 100%;
    width: ac(160px, 153px);
    @mixin aspect-ratio 160, 35;
    flex-shrink: 0;
    transition: transform 0.25s ease, width 0.25s ease;
    z-index: 100;
    margin-right: auto;

    &:hover {
      transform: scale(1.06);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }

    @mixin mobile-menu-end-point {
      &__image {
        display: none;
      }
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /*transition: opacity 0.2s ease;
    opacity: 0;*/

    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      padding-left: ac(60px, 20px);
      align-items: center;

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(25px, 20px);
        }

        &:after {
          display: none;
        }
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .menu-item {
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\e999';
          font-family: 'icomoon';
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  .menu-additional-content {
    display: none;

    @mixin mobile-menu-start-point {
      /*transform: translateY(-8px);*/
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: auto;

      .btn {
        width: 100%;
      }
    }

    @mixin max-sm {
      .menu-additional-content__buttons {
        width: 100%;
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @mixin mobile-menu-end-point {
        padding: 5px 0;
      }

      &:hover {
        @mixin mobile-menu-end-point {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: 13px;
    font-family: var(--font-main);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-white);
    transition: all 0.4s ease;
    cursor: pointer;

    /*&::before {
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--cl-white);
      transition: all 0.3s ease;
    }*/

    /*&:hover {
      color: var(--cl-yellow);

      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-yellow);
      }
    }*/

    &.active {
      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-white);
      }
    }

    &.is-hover {
      opacity: 0.5;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-grey);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: calc(var(--vh) * 100);
      min-width: 320px;
      padding-top: ac(132px, 120px);
      background: var(--cl-black);
      padding-right: 5px;
      padding-bottom: 60px;
      /*border-bottom-left-radius: 28px;*/
      transition: opacity 0.45s ease-out, clip-path 0.5s ease,
        background-color 0.45s ease 0.1s, border-radius 0.45s ease;
      clip-path: circle(25px at calc(100% - 55px) ac(75px, 30px));
      opacity: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      pointer-events: none;

      @media screen and (max-height: 730px) {
        padding-top: 110px;
      }

      @mixin sm {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      &__image {
        position: absolute;
        inset: -10px;
        transform: translateY(20px);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: bottom;
        }
      }

      &.active {
        pointer-events: auto;
        clip-path: circle(75%);
        opacity: 1;
      }

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        padding-right: 15px;
        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;
        overflow-x: hidden;
        overflow-y: auto;

        .simplebar-wrapper {
          flex: 1;
        }

        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          height: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            grid-column-gap: ac(60px, 20px);
            grid-row-gap: 24px;
            /*flex-direction: column;*/
            align-items: center;
            justify-content: center;

            .menu-item {
              width: auto;
            }
          }
        }

        .simplebar-track.simplebar-vertical {
          width: ac(6px, 4px);
          background: var(--cl-medium-grey);
          border-radius: 4px;
          /*transform: translateX(ac(6px, 4px));*/
          .simplebar-scrollbar {
            background: var(--cl-purple);
            border-radius: 4px;
            &:before {
              content: none;
            }
          }
        }
      }

      .menu-link {
        padding: 0;
        font-size: ac(50px, 24px);
        font-weight: 500;
        line-height: normal;
        margin-left: 0;
        width: auto;
      }

      .menu-item {
        /*width: 100%;*/
        margin: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        /*&:last-of-type {
          margin-bottom: 32px;
        }*/

        /*@mixin media-height 600 {
          padding-bottom: 8px;
          margin-bottom: 8px;

          &:last-of-type {
            margin-bottom: 20px;
          }
        }*/
      }

      @mixin max-sm {
        width: 100%;
        height: calc(var(--vh) * 100);
        padding-bottom: 40px;
        border-bottom-left-radius: 0;

        .menu {
          padding-left: 20px;

          .simplebar-content {
            .menu-list {
              flex-direction: column;
              margin-bottom: 80px;
            }
          }
        }

        .menu-link {
          font-size: 40px;
        }

        .menu-additional-content {
          margin-top: auto;
          justify-content: flex-end;

          &__buttons {
          }
        }
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  background: var(--cl-white);
  position: relative;
  z-index: 100;
  clip-path: inset(0 0 0 0 round 50%);
  overflow: hidden;
  transition: background-color 0.3s ease;

  /*&:before {
    z-index: 1;
    inset: 0;
    content: '';
    position: absolute;
    background: var(--gradient);
    transition: opacity 0.3s ease;
    opacity: 0;
  }*/

  .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    user-select: none;
    width: 43px !important;
    height: 43px !important;
    position: relative;
    z-index: 2;
  }

  i.icon-plus {
    opacity: 1;
    transition: opacity 0.3s ease, transform 0.4s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(0deg);
    font-size: ac(16px, 14px);
    color: var(--cl-black);
  }

  &.active {
    /*&:before {
      opacity: 1;
    }*/

    i.icon-plus {
      opacity: 0;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }

    .burger-icon {
      transform: rotate(45deg);
    }

    .top {
      stroke-dashoffset: -68px;
    }

    .bottom {
      stroke-dashoffset: -68px;
    }

    .line {
      stroke: var(--cl-black);
      opacity: 1;
    }
  }

  .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
      stroke-dashoffset 400ms, opacity 0.3s ease;
    stroke: var(--cl-black);
    stroke-width: 3.5;
    stroke-linecap: round;
    opacity: 0;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  &:hover {
    background-color: var(--cl-blue);

    .line {
      stroke: var(--cl-dark-blue);
    }
  }

  &.active {
    &:hover {
      .line {
        stroke: var(--cl-dark-blue);
      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
