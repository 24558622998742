.related-jobs-section {
  padding: ac(105px, 30px) 0 ac(140px, 80px);
  background: linear-gradient(179deg, rgba(0, 0, 15, 0) 1.28%, #4cb9f2 126.08%);
  overflow: hidden;
  z-index: 5;

  .title-container {
    margin-bottom: ac(50px, 40px);
    .title-animation {
      margin-right: 50px;
    }
  }

  .related-jobs-swiper-wr {
    .swiper {
      overflow: visible;
    }

    .swiper-slide {
      height: auto;
      display: flex;
      align-items: stretch;
      justify-content: stretch;

      .job-card {
        width: 100%;
      }

      .job-card__btn {
        margin-top: auto;
      }
    }
  }
}
