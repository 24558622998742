.for-clients-section {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &__image {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    /*&:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      height: 47.44%;
      background: linear-gradient(0deg, rgba(4, 0, 0, 0) 0%, #040000 100%);
    }*/

    @mixin max-sm {
      object-position: 48% 50%;
    }
  }

  &__decor {
    @mixin aspect-ratio 2149, 1477;
    position: absolute;
    z-index: 1;
    width: 149.24%;
    left: 50%;
    top: 50%;
    transform: translateX(-51%) translateY(-50.5%);
    mix-blend-mode: hard-light;
    min-width: 1222px;
    filter: brightness(105%);
    opacity: 0.9;
  }

  &__wrap {
    padding-top: 88px;
    padding-bottom: 88px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 4;
  }

  &__content {
    width: 100%;
    margin-top: 5px;
  }

  &__buttons {
    .btn {
      min-width: ac(160px, 168px);
    }
  }

  &__title-container {
    width: 100%;

    h2 {
      font-size: ac(70px, 40px);
      line-height: normal;
      font-weight: 500;
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 48px);
    }

    .line-decor {
      margin-bottom: calc((ac(70px, 40px) * 1.02) / 2);

      &:before {
        background: var(--cl-white);
      }
    }

    .line-decor.animated::before {
      width: 111%;
    }
  }

  &__title {
    max-width: ac(520px, 316px);

    h2 {
      letter-spacing: -0.5px;
    }
  }

  @mixin min-media-height 1080 {
    &__wrap {
      min-height: 1080px;
    }
  }

  @mixin media 1025 {
    &__wrap {
      padding-top: ac(200px, 88px);
      padding-bottom: ac(200px, 88px);
      min-height: 600px;
    }
  }

  @mixin max-lg {
    /*&__wrap {
      min-height: auto;
    }*/
  }

  @mixin max-sm {
    &__title-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      &:not(:last-child) {
        margin-bottom: ac(40px, 48px);
      }

      .line-decor {
        margin-bottom: 0;
        width: 29.85%;
        margin-left: auto;
        flex: auto;
        height: 1px;
      }

      .line-decor.animated::before {
        width: 100%;
      }
    }

    &__content {
      margin-top: 60px;
    }

    &__title h2 {
      transform: translateY(20px);
    }

    &__decor {
      transform: translateX(-43.1%) translateY(-47.5%) rotate(-4deg);
    }
  }

  @mixin max-xs {
    &__title-container {
      .line-decor {
        width: 22%;
      }
    }
  }

  &.jobs-type {
    .for-clients-section__title {
      max-width: ac(500px, 290px);
    }

    .for-clients-section__title-container {
      position: relative;

      .line-decor.animated::before {
        width: 125%;

        @mixin max-sm {
          width: 150%;
        }
      }

      /*.line-decor {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 0;
        width: 76.18%;
      }*/
    }

    .for-clients-section__title {
      @mixin max-md {
        max-width: 320px;
      }

      @mixin media 460 {
        max-width: 290px;
      }
    }
  }

  &.markets-type {
    @mixin max-sm {
      .for-clients-section__image {
        opacity: 0.65;
      }

      .for-clients-section__decor {
        opacity: 0.9;
      }
    }
  }
}
