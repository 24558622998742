.gallery-section {
  padding-top: ac(80px, 30px);
  padding-bottom: ac(80px, 30px);
  overflow: hidden;
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;
    margin-left: 0;

    @mixin media 1281 {
      max-width: ac(627px, 322px);
    }

    @mixin sm {
      .slider-buttons {
        display: none;
      }
    }

    @mixin max-sm {
      width: 100%;
      max-width: 100%;
    }
  }

  &__slider-item {
    clip-path: inset(0 0 0 0 round ac(50px, 20px));

    img {
      transition: transform 0.3s ease;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }

    @mixin sm {
      @mixin aspect-ratio 844, 600;
      width: ac(844px, 520px);

      &.rectangular-type {
        @mixin aspect-ratio 844, 600;
        width: ac(844px, 520px);
      }

      &.vertical-rectangular-type {
        @mixin aspect-ratio 412, 600;
        width: ac(412px, 254px);
      }

      &.square-type {
        @mixin aspect-ratio 628, 600;
        width: ac(628px, 387px);
      }
    }

    @mixin max-sm {
      width: 100%;
      height: 370px;
    }
  }

  + .about-us-section {
    margin-top: ac(-270px, -304px);
  }

  + .markets-section {
    margin-top: ac(-255px, -230px);
  }
}
