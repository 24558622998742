.teams-section {
  padding-top: ac(120px, 60px);
  background: linear-gradient(0deg, #00000f 1.28%, #4cb9f2 126.08%);
  padding-bottom: ac(141px, 60px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  &__title-container {
    width: 23.82%;
    padding-right: 20px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin min-media 952 {
      .line-decor {
        position: absolute;
        right: 0;
        top: 0;
        margin-bottom: 0;
        width: 150%;

        &.revert-line {
          transform: scale(1, -1) rotate(90deg) !important;
          transform-origin: right;
        }
      }
    }
  }

  &__content {
    width: 66.14%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    grid-column-gap: 20px;
    grid-row-gap: ac(50px, 30px);
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    margin: 0;

    .swiper-wrapper {
      height: ac(700px, 750px);
    }

    .slider-buttons {
      margin-top: -50px;
      position: relative;
      z-index: 20;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /*width: calc(25% - 15px);*/
    cursor: pointer !important;
    margin-bottom: ac(48px, 24px);
    height: min-content;

    /*@mixin max-xl {
      width: calc(33% - 15px);
    }*/

    &:hover {
      img {
        transform: scale(1.03);
      }

      .teams-section__title {
        color: var(--cl-medium-grey);
      }

      .teams-section__subtitle {
        color: var(--cl-medium-grey);
      }
    }
  }

  &__item-image {
    @mixin aspect-ratio 1, 1;
    width: 100%;
    clip-path: inset(0 0 0 0 round 50%);
    margin-bottom: 17px;
    cursor: pointer;

    img {
      transition: transform 0.3s ease;
    }
  }

  &__item-title {
    margin-bottom: 8px;
    text-align: center;
    font-size: ac(23px, 16px);
    font-weight: 600;
    line-height: normal;
    transition: color 0.3s ease;
    cursor: pointer;

    @mixin media 769 {
      letter-spacing: -0.5px;
    }

    @mixin media 380 {
      font-size: 14px;
    }

    @mixin media 335 {
      font-size: 13px;
    }
  }

  &__item-subtitle {
    font-size: ac(18px, 14px);
    font-weight: 500;
    line-height: normal;
    text-align: center;
    transition: color 0.3s ease;
    cursor: pointer;

    @mixin media 1400 {
      font-size: ac(16px, 14px);
    }

    @mixin media 769 {
      letter-spacing: -0.5px;
    }

    @mixin media 380 {
      font-size: 12px;
    }

    @mixin media 335 {
      font-size: 11px;
    }
  }

  @mixin media 1340 {
    &__content {
      width: 70%;
    }

    &__wrap {
      max-width: 100%;
    }

    .cont {
      max-width: 88vw;
    }
  }

  @mixin media 1279 {
    &__content {
      width: 68%;
    }

    &__slider {
      .swiper-wrapper {
        height: 800px;
      }
    }
  }

  @mixin media 1025 {
    &__slider {
      .swiper-wrapper {
        height: 650px;
      }
    }
  }

  @mixin media 961 {
    &__content {
      width: 62%;
    }

    &__title-container {
      width: 30%;
    }
  }

  @mixin media 951 {
    &__wrap {
      align-items: flex-start;
      flex-wrap: wrap;
    }
    &__title-container {
      width: 100%;
      align-items: flex-end;
    }

    &__title {
      max-width: 160px;
    }

    &__content {
      width: 100%;
      margin-top: 30px;
    }
  }

  @mixin media 769 {
    &__slider {
      .swiper-wrapper {
        height: 90vw;
      }
    }
  }

  @mixin media 691 {
    &__slider {
      .swiper-wrapper {
        height: 98vw;
      }
    }
  }

  @mixin media 652 {
    &__slider {
      .swiper-wrapper {
        height: 140vw;
      }
    }
  }

  @mixin media 452 {
    &__slider {
      .swiper-wrapper {
        height: 150vw;
      }
    }
  }

  @mixin media 390 {
    .cont {
      max-width: 91vw;
    }

    &__slider {
      .swiper-wrapper {
        height: 160vw;
      }
    }
  }

  @mixin media 375 {
    &__slider {
      .swiper-wrapper {
        height: 170vw;
      }
    }
  }

  @mixin media 335 {
    &__slider {
      .swiper-wrapper {
        height: 155vw;
      }
    }
  }

  + .for-you-section {
    padding-top: ac(0px, 10px);
  }
}
