.consultant-block {
  @mixin aspect-ratio-block 408, 432;
  width: ac(408px, 309px);
  z-index: 1;

  &__image-main,
  &__image-second {
    @mixin aspect-ratio 1, 1;
    position: absolute;
    /*border-radius: 50%;*/
    clip-path: inset(0 0 0 0 round 50%);

    img {
      transition: all 0.25s linear;
    }
  }

  &__image-main {
    width: ac(300px, 200px);
    top: 0;
    left: 0;

    /*&:hover {
      img {
        transform: scale(1.02);
      }
    }*/
  }

  &__image-second {
    width: ac(363px, 238px);
    z-index: -1;
    bottom: 0;
    right: 0;

    &.with-gradient-blur {
        img {
        filter: grayscale(100%) brightness(115%);
      }

      &:after {
        position: absolute;
        background: var(--gradient);
        inset: 0;
        z-index: 1;
        content: '';
        mix-blend-mode: soft-light;
      }

      .consultant-block__image-second-before {
        position: absolute;
        z-index: 2;
        inset: 0;
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(6px);

        &:before {
          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;
          background: #1400ff;
          opacity: 0.1;
        }
      }
    }

    /*img {
      filter: grayscale(100%) brightness(115%);
    }

    &:after {
      position: absolute;
      background: var(--gradient);
      inset: 0;
      z-index: 1;
      content: '';
      mix-blend-mode: soft-light;
    }*/
  }

  /*&__image-second-before {
    position: absolute;
    z-index: 2;
    inset: 0;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(6px);

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
      background: #1400ff;
      opacity: 0.1;
    }
  }*/
}
