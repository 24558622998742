.offer-card {
  position: relative;
  clip-path: inset(1px 1px 1px 1px round ac(50px, 25px));

  &::before {
    content: '';
    display: block;
    padding-top: 150%;
  }
  &__bg {
    position: absolute;
    inset: 0;
    z-index: 0;
    background: var(--cl-black);
    /*border-radius: ac(50px, 25px);
    overflow: hidden;
    box-shadow: inset 1px 1px 5px 0px var(--cl-black),
      inset -1px -1px 5px 0px var(--cl-black);*/
    mask-image: radial-gradient(white, black);
    -webkit-mask-image: radial-gradient(white, black);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: -2px;
      background: linear-gradient(180deg, rgba(0, 0, 15, 0) 0%, #00000f 100%);
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }
  &__content-wr {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: ac(82px, 30px) ac(82px, 20px) 0;
    display: flex;
    align-items: flex-end;
  }
  &__content {
    max-height: 100%;
    overflow-y: auto;
    text-align: center;
    padding-right: 10px;
    width: calc(100% + 10px);
    flex-shrink: 0;

    .simplebar-track.simplebar-vertical {
      width: 3px;
      background: var(--cl-dark-blue);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }
  }
  .buttons {
    justify-content: center;
  }
  &__title {
    margin-bottom: 40px;
    font-size: ac(50px, 30px);
    font-weight: 500;
    line-height: 1.2;
    transition: 0.3s;
  }
  &__text {
    padding-bottom: 50px;
    font-weight: 400;
    line-height: 1;
    display: none;
  }
  &.hover {
    .offer-card__title {
      margin-bottom: 20px;
    }
  }
}
