/*
Styles for popup background and basic styles for fonts
*/

:root {
  --white: var(--cl-white);
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: 1px solid #f5f5f5;
  border-right: 0;
  border-bottom: 0;
  background: linear-gradient(122deg, #00000f 0%, #4cb9f2 118.94%);

  color: var(--cl-white);
  font-family: var(--font-main);
  border-radius: 20px 0 0 0;

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 13px !important;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
    text-transform: capitalize;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--white);
  }

  #cc-nb-text {
    font-weight: 400;
  }
  #cc-nb-title {
    color: var(--white);
    font-family: var(--font-main);
  }
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    background: transparent;
    padding: 2px 2px 2px 2px;
    position: relative;
    overflow: visible;
    border: none;
    border-radius: 0;
    text-align: center;
    width: fit-content;
    z-index: 2;
    cursor: pointer;
    align-items: center;
    font-family: var(--font-main);
    display: inline-flex;
    justify-content: center;
    padding-left: ac(53px, 50px);
    transition: all 0.4s ease;
    padding-top: ac(12px, 10px);
    padding-bottom: ac(12px, 10px);
    font-size: 16px;
    font-weight: 600;
    color: var(--cl-white);
    line-height: normal;

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }

    &:after {
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
      content: '\e900';
      color: var(--white);
      /*top: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0.8);
            r: 0;*/
      display: flex;
      margin-left: ac(11px, 8px);
      font-size: ac(11px, 10px);
      transition: transform 0.25s ease;
      display: none;
    }

    &:before {
      content: '';
      transition: all 0.5s ease;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 6px 30px 30px 6px;
      background: var(--cl-blue);
      display: flex;
      justify-content: center;
      align-items: center;
      width: ac(43px, 40px);
    }

    &:hover {
      padding-left: 0;
      padding-right: ac(53px, 50px);

      /*&:after {
        transform: translateX(6px);
      }*/

      &:before {
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    grid-column-gap: 18px;
    grid-row-gap: 0;
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: 1px solid var(--cl-blue);
    border-radius: 20px;
    overflow: hidden;
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: linear-gradient(122deg, #00000f 0%, #4cb9f2 118.94%) !important;
    border-top-color: var(--cl-blue) !important;
    border-bottom-color: var(--cl-blue) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-body-tabs {
    background-color: var(--cl-blue);
  }

  .cc-cp-body-tabs-item {
    background: var(--cl-blue);
  }

  .cc-cp-body-tabs-item-link {
    color: var(--dark);

    &:focus {
      box-shadow: none;
    }
  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--gradient) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--cl-black);
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--cl-black);
      padding: 5px;
      background: var(--cl-blue);
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--cl-dark-blue);
    transition: all 0.3s ease;

    &:hover {
      color: var(--white);
      transform: scale(1.1);
    }

    &:focus {
      border: 0;
    }
  }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--cl-blue);
      text-decoration: underline;
      transition: all 0.3s ease;

      &:hover {
        color: var(--cl-white);
        text-decoration-color: var(--cl-dark-blue);
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    background: transparent;
    padding: 2px 2px 2px 2px;
    position: relative;
    overflow: visible;
    border: none;
    border-radius: 0;
    text-align: center;
    width: fit-content;
    z-index: 2;
    cursor: pointer;
    align-items: center;
    font-family: var(--font-main);
    display: inline-flex;
    justify-content: center;
    padding-left: ac(53px, 50px);
    transition: all 0.4s ease;
    padding-top: ac(12px, 10px);
    padding-bottom: ac(12px, 10px);
    font-size: 16px;
    font-weight: 600;
    color: var(--cl-white);
    line-height: normal;

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }

    &:after {
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
      content: '\e900';
      color: var(--white);
      /*top: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0.8);
            r: 0;*/
      display: flex;
      margin-left: ac(11px, 8px);
      font-size: ac(11px, 10px);
      transition: transform 0.25s ease;
      display: none;
    }

    &:before {
      content: '';
      transition: all 0.5s ease;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 6px 30px 30px 6px;
      background: var(--cl-blue);
      display: flex;
      justify-content: center;
      align-items: center;
      width: ac(43px, 40px);
    }

    &:hover {
      padding-left: 0;
      padding-right: ac(53px, 50px);

      /*&:after {
        transform: translateX(6px);
      }*/

      &:before {
        left: 100%;
        transform: translateX(-100%);
      }
    }

    @mixin max-sm {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
  }
}

/*
Styles for mobile adaptation
*/
@mixin max-sm {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }
}
