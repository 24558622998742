:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(844px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.apply-now-popup {
      border-radius: 20px;
      border: 1px solid #f5f5f5;
      background: linear-gradient(122deg, #00000f 0%, #4cb9f2 118.94%);
    }

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--cl-white);
    font-size: ac(30px, 24px);
    line-height: normal;
    font-weight: 600;
    /*text-transform: uppercase;*/
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(30px, 16px);
    top: ac(30px, 16px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ac(24px, 22px);
    height: ac(24px, 22px);
    border-radius: 50%;
    background: var(--cl-white);

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(12px, 11px);
      font-weight: 700;
      color: var(--cl-black);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);
    width: calc(100% + 10px);
    padding-right: 10px;

    .simplebar-track.simplebar-vertical {
      width: ac(4px, 3px);
      background: #f3f3f3;
      border-radius: 2px;

      .simplebar-scrollbar {
        background: var(--cl-dark-blue);
        border-radius: 0;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      .meta-input {
        label {
          padding-bottom: 16px;
        }

        input,
        textarea {
          font-size: ac(20px, 16px);
          height: ac(52px, 48px);
          padding-left: ac(20px, 18px);
          padding-right: ac(20px, 18px);
        }

        textarea {
          min-height: ac(100px, 80px);
          max-height: ac(100px, 80px);
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  &__block {
    &.team-popup {
      padding: 0;
      background: transparent;

      .team-popup__block {
        padding: 1px;
        border-radius: 20px;

        &:after {
          padding: 2px;
          background: linear-gradient(122deg, #f5f5f5 0%, #4cb9f2 118.94%);
        }
      }

      .team-popup__block-wrap {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: ac(68px, 30px);
        padding: ac(60px, 28px);
        padding-right: ac(40px, 18px);
        background: linear-gradient(122deg, #00000f 0%, #4cb9f2 118.94%);
        border-radius: 20px;
        position: relative;

        @mixin media 590 {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        @mixin media 350 {
          padding-left: 20px;
          padding-right: 10px;
        }
      }

      .team-popup__image {
        @mixin aspect-ratio 1, 1;
        clip-path: inset(0 0 0 0 round 50%);
        width: ac(196px, 160px);
        min-width: ac(196px, 160px);
        height: 100%;

        img {
          transition: all 0.25s ease;
        }

        &:hover {
          img {
            transform: scale(1.03);
          }
        }

        @mixin media 590 {
          width: 140px;
          min-width: 140px;
        }
      }

      .popup__content {
        width: calc(100% - (ac(196px, 160px)) + ac(68px, 30px));
        max-width: calc(100% - (ac(196px, 160px)) + ac(68px, 30px));
        padding-right: ac(20px, 10px);

        .simplebar-track.simplebar-vertical {
          top: 10px;
        }

        @mixin media 590 {
          width: 100%;
          max-width: 100%;
          max-height: calc(var(--vh, 1vh) * 50);
        }
      }

      .team-popup__wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .team-popup__title {
        line-height: normal;

        &:not(:last-child) {
          margin-bottom: ac(8px, 10px);
        }
      }

      .team-popup__socials {
        margin-left: ac(26px, 20px);
        gap: 10px;

        .social-link {
          color: var(--cl-white);
          font-size: ac(32px, 24px);

          &:hover {
            color: var(--cl-blue);
            transform: none;
          }
        }
      }

      .team-popup__subtitle {
        width: 100%;
        line-height: normal;
        font-size: ac(23px, 18px);

        &:not(:last-child) {
          margin-bottom: ac(8px, 10px);
        }

        @mixin media 350 {
          font-size: 17px;
        }
      }

      .team-popup__links {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &:not(:last-child) {
          margin-bottom: ac(29px, 18px);
        }
      }

      .team-popup__link {
        position: relative;
        transition: color 0.3s ease;
        font-size: ac(18px, 16px);
        line-height: normal;
        max-width: 100%;
        word-wrap: break-word;
        overflow-wrap: break-word;

        /*&:before {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          opacity: 0;
          transition: opacity 0.3s ease, width 0.3s ease,
            background-color 0.3s ease;
          background: var(--cl-dark-blue);
          content: '';
          height: 2px;
        }*/

        &:hover {
          color: var(--cl-blue);
          /* &:before {
            opacity: 1;
            right: auto;
            left: 0;
            width: 100%;
          }*/
        }

        &:not(:last-child) {
          margin-bottom: ac(8px, 6px);
        }

        @mixin media 350 {
          font-size: 14px;
        }
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
