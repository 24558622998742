.for-you-section {
  padding-top: ac(160px, 60px);
  padding-bottom: ac(160px, 60px);

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title-container {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(36px, 30px);
    }

    .line-decor.animated::before {
      width: 140%;

      @mixin max-xl {
        width: 130%;
      }

      @mixin media 421 {
        width: 140%;
      }
    }
  }

  &__title {
    max-width: ac(520px, 300px);

    @mixin media 421 {
      max-width: 200px;
    }

    @mixin max-xs {
      max-width: 180px;
    }
  }

  &__content {
    width: 49.22%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(57px, 30px);
    }

    @mixin max-md {
      width: 100%;
      order: 1;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(27px, 24px);
    }
  }

  &__testimonials {
    margin-bottom: 40px;
    max-width: 36.6%;

    .testimonials-slider__item {
      position: relative;

      @mixin sm {
        padding-left: 55px;

        .testimonials-slider__item-quote {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(57px, 30px);
    }
    @mixin max-xl {
      max-width: 42%;
    }

    @mixin media 901 {
      max-width: 46%;
    }

    @mixin max-md {
      width: 100%;
      max-width: 100%;
      order: 3;
    }
  }

  &__vacancies {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(20px, 16px);
    width: 49.22%;

    @mixin media 901 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      @mixin md {
        width: 304px;
      }
    }

    @mixin max-md {
      width: 100%;
      order: 2;

      &:not(:last-child) {
        margin-bottom: ac(27px, 24px);
      }
    }
  }

  &__items {
    width: 36.6%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    .consultant-block {
      @mixin aspect-ratio-block 467, 366;
      overflow: visible;
      width: 100%;

      .consultant-block__image-main {
        right: auto;
        left: 0;
        top: auto;
        bottom: 0;
      }

      .consultant-block__image-second {
        right: 0;
        left: auto;
        top: 0;
        bottom: auto;
      }
    }

    @mixin max-xl {
      width: 42%;
    }

    @mixin media 901 {
      width: 46%;
    }

    @mixin max-md {
      width: 80%;
      margin-left: auto;
      max-width: 367px;
      order: 4;
    }

    @mixin media 421 {
      width: 100%;
    }
  }
}
