.insights-section{
    padding: 26px 0 ac(160px, 80px);
    &-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    .border-btn{
        margin-top: 70px;
    }
}

@media (max-width: 650px) {
    .insights-section{

        &-grid{
           grid-template-columns: 1fr;
        }

    }

}