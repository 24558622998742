.meta-input,
.meta-upload-file {
  label {
    font-family: var(--font-main);
    font-size: ac(20px, 16px);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-white);
    text-transform: uppercase;
    padding-bottom: ac(30px, 20px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-blue);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input,
  textarea {
    width: 100%;
    font-size: ac(30px, 20px);
    line-height: normal;
    font-weight: 400;
    font-family: var(--font-main);
    color: var(--cl-black);
    border: 2px solid var(--cl-white);
    border-radius: ac(42px, 33px);
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 5px ac(49px, 24px);
    height: ac(80px, 64px);
    background: var(--cl-white);
    @mixin transparent-bg-input var(--cl-black);
    caret-color: var(--cl-black) !important;

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-black);
      opacity: 0.5;
    }

    &:focus {
      border-color: var(--cl-dark-blue);
      caret-color: var(--cl-black) !important;
      box-shadow: 2px 2px 5px 0px rgba(var(--cl-dark-blue-rgb) / 0.3);
    }

    &.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      caret-color: var(--cl-black) !important;
    }
  }

  textarea {
    padding-top: 20px;
    min-height: ac(160px, 140px);
    max-height: ac(160px, 140px);
    overflow-y: auto;
    resize: none;
    clip-path: inset(0 0 0 0 round ac(42px, 33px));

    scrollbar-width: thin;
    scrollbar-color: var(--cl-dark-blue) var(--cl-blue);

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--cl-blue);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-dark-blue);
      border-radius: 2px;
    }
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-white);
  }

  &__error {
    color: var(--cl-red);
  }

  &__search-btn {
    position: absolute;
    right: ac(50px, 20px);
    top: 54%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    font-size: ac(28px, 24px);
    color: var(--cl-black);
    transition: all 0.3s ease;

    &:hover {
      color: var(--cl-dark-blue);
    }
  }

  &.search-input {
    position: relative;

    input {
      height: ac(80px, 64px);
      border-radius: ac(41px, 32px);
      padding-left: ac(49px, 24px);
      padding-right: ac(100px, 60px);
      font-size: ac(30px, 20px);
      line-height: normal;
      font-weight: 400;
      caret-color: var(--cl-white);
    }
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    padding-bottom: 16px;
  }

  &__input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(20px, 16px);
    line-height: normal;
    font-weight: 400;
    font-family: var(--font-main);
    color: var(--cl-white);
    border: 2px dashed var(--cl-white);
    border-radius: ac(42px, 33px);
    transition: all 0.3s ease;
    padding: 8px ac(16px, 12px);
    cursor: pointer;
    position: relative;
    min-height: ac(100px, 80px);

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
    }

    &:hover {
      border-color: var(--cl-blue);
    }
  }

  &__info {
    padding-top: 6px;
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--font-main);
    text-align: center;
    color: var(--cl-blue);
    margin-left: auto;
    margin-right: auto;
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-medium-grey);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }

  &.btn-styles-type {
    .meta-upload-file__input {
      border: 1px solid var(--cl-white);
      border-radius: 6px 30px 30px 6px;
      min-width: 176px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 44px;
    }

    .meta-upload-file__name {
      color: var(--cl-white);
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      position: relative;
      z-index: 2;
      transition: all 0.3s ease;
      font-family: var(--font-main);
      @mixin max-line-length-one;
      max-width: 290px;
      pointer-events: none;

      @mixin media 350 {
        max-width: 240px;
      }
    }

    &:hover {
      .meta-upload-file__input {
        border-color: var(--cl-blue);
      }

      .meta-upload-file__name {
        color: var(--cl-blue);
      }
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(18px, 16px);
    min-width: ac(18px, 16px);
    height: ac(18px, 16px);
    border: 2px solid var(--cl-white);
    margin-right: ac(8px, 6px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--cl-blue);
    }

    .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--cl-white);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 4px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-weight: 400;
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-family: var(--font-main);
    color: var(--cl-white);

    a {
      display: inline-block;
      font: inherit;
      font-weight: 700;
      color: var(--cl-white);
      text-decoration: underline;
      text-decoration-color: var(--cl-white);
      transition: all 0.25s ease;

      &:hover {
        color: var(--cl-blue);
        text-decoration-color: var(--cl-blue);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
    }

    &:hover {
      border-color: var(--cl-white);
    }
  }
}

.btn-checkbox {
  cursor: pointer;
  max-width: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ac(14px, 12px);
    padding-right: ac(14px, 12px);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    font-family: var(--font-main);
    font-size: ac(16px, 14px);
    font-weight: 400;
    line-height: normal;
    color: var(--cl-white);
    background-color: var(--cl-black);
    border: 1px solid var(--cl-white);
    transition: all 0.3s ease;

    span {
      max-width: 100%;
      @mixin max-line-length-one;
    }

    &:hover {
      border-color: var(--cl-blue);
      color: var(--cl-blue);
    }

    @mixin media 1100 {
      @mixin sm {
        font-size: 13px;
      }
    }

    @mixin media 374 {
      font-size: 13px;
    }
  }

  input {
    display: none;
  }

  input:checked ~ .btn-checkbox__content {
    background-color: var(--cl-blue);
    color: var(--cl-black);

    &:hover {
      border-color: var(--cl-blue);
    }
  }
}

.text-checkbox {
  cursor: pointer;
  max-width: 100%;

  &__content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-main);
    font-size: ac(16px, 15px);
    font-weight: 400;
    line-height: normal;
    color: var(--cl-grey);
    transition: all 0.3s ease;
    position: relative;

    &:before {
      content: '+';
      font: inherit;
      color: var(--cl-red);
      font-weight: 500;
      transition: all 0.3s ease;
      margin-right: 6px;
      min-width: 8.7px;
    }

    span {
      max-width: 100%;
      text-decoration: underline;
      text-decoration-color: transparent;
      /*@mixin max-line-length-one;*/
      transition: all 0.3s ease;

      &:hover {
        text-decoration-color: var(--cl-grey);
      }
    }

    &:hover {
      color: var(--cl-dark-grey);
    }
  }

  input {
    display: none;
  }

  input:checked ~ .text-checkbox__content {
    color: var(--cl-red);

    &:before {
      /*content: '●';*/
      content: '+';
      /*clip-path: inset(10px 0 0 0);
      transform: translateY(-2px);*/
      transform: rotate(45deg) scale(1.3);
    }

    span {
      &:hover {
        text-decoration-color: var(--cl-red);
      }
    }
  }
}
