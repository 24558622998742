.something-section {
  padding-top: ac(160px, 60px);
  padding-bottom: ac(148px, 60px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title-container {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(36px, 30px);
    }
  }

  &__title {
    max-width: ac(330px, 198px);
  }

  &__content {
    width: 40.75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(60px, 48px);
    }
  }

  &__buttons {
    &--mob {
      display: none;
    }
  }

  &__testimonials {
    margin-bottom: 40px;
    max-width: calc(100% - ac(53px, 0px));
    margin-left: auto;

    .testimonials-slider__item {
      position: relative;

      @mixin sm {
        padding-left: 55px;

        .testimonials-slider__item-quote {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  &__items {
    width: 57.68%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: ac(-165px, -50px);

    .consultant-block {
      overflow: visible;

      .job-card {
        width: ac(304px, 197px);
        position: absolute;
        top: ac(-12px, -46px);
        left: ac(-214px, -74px);
      }

      @mixin media 850 {
        @mixin md {
          .consultant-block__image-main {
            left: 55px;
          }
        }
      }

      &:nth-child(2n) {
        z-index: 10;
        margin-right: auto;
        margin-top: ac(-130px, 10px);
        margin-left: ac(0px, 10px);

        .job-card {
          position: absolute;
          top: auto;
          left: auto;
          bottom: ac(88px, 12px);
          right: ac(-220px, -104px);
        }

        .consultant-block__image-main {
          left: auto;
          right: 0;
        }

        .consultant-block__image-second {
          left: 0;
          right: auto;
          transform: translateY(-10px);
        }
      }
    }
  }

  @mixin max-md {
    &__content {
      width: 100%;
    }

    &__items {
      margin-top: 34px;
      width: 100%;

      .consultant-block {
        overflow: visible;
        &:first-of-type{
          .consultant-block__image-main{
            bottom: 80px;
          }
          .consultant-block__image-second{
            bottom: -48px;
          }
          .job-card{
            & + .consultant-block__image-main{
              bottom: 0;
            }
            & ~ .consultant-block__image-second{
              bottom: -77px;
            }
          }
        }

        .job-card {
          width: 277px;
          left: -200px;
        }

        &:nth-child(2n) {
          margin-top: -60px;
          margin-left: 0;
        }
      }
    }
  }

  @mixin media 590 {
    &__items {
      margin-top: 34px;

      .consultant-block {
        @mixin aspect-ratio-block 304, 286;
        overflow: visible;
        width: 100%;
        max-width: 304px;
        margin-left: 0;
        margin-right: auto;

        .job-card {
          width: 277px;
          left: 0;
          top: 0;
        }

        .consultant-block__image-main {
          left: auto;
          top: auto;
          bottom: 0;
          right: 0;
        }

        .consultant-block__image-second {
          left: auto;
          top: auto;
          bottom: -77px;
          right: -110px;
        }

        &:nth-child(2n) {
          margin-top: 0;
          margin-right: auto;

          @mixin aspect-ratio-block 338, 292;
          overflow: visible;
          width: 100%;
          max-width: 338px;

          .job-card {
            left: auto;
            top: auto;
            right: 0;
            bottom: 0;
          }

          .consultant-block__image-main {
            left: 0;
            top: -2px;
            bottom: auto;
            right: auto;
          }

          .consultant-block__image-second {
            left: -61px;
            top: auto;
            bottom: 6px;
            right: auto;
          }
        }
      }
    }

    &__title-container {
      .line-decor.animated::before {
        width: 126%;
      }
    }

    &__testimonials {
      margin-bottom: 5px;
    }

    &__buttons {
      display: none;

      &--mob {
        margin-top: 30px;
        display: flex;
      }
    }
  }

  @mixin media 374 {
    &__title-container {
      .line-decor.animated::before {
        width: 135%;
      }
    }
  }

  @mixin max-xs {
    &__title-container {
      .line-decor.animated::before {
        width: 155%;
      }
    }
  }
}
