.our-value-section {
  padding-top: ac(0px, 45px);
  padding-bottom: 20px;
  margin-bottom: -20px;
  overflow: hidden;
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(40px, 32px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;
    margin-left: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: ac(350px, 320px);
    padding: ac(20px, 24px);
    border-radius: 10px;
    background: var(--cl-white);
    height: auto;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;

    &:hover {
      box-shadow: 2px 2px 10px 5px rgba(255, 255, 255, 0.2);

      .our-value-section__item-line {
        transform: scale(1.1);

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__item-title {
    &:not(:last-child) {
      margin-bottom: ac(10px, 12px);
    }
  }

  &__item-text {
    max-height: calc((ac(18px, 16px) * 1.5) * 8);
    width: calc(100% + 8px);
    padding-right: 8px;
    margin-bottom: 20px;

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-light-grey);
      border-radius: 2px;
      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 2px;
        &:before {
          content: none;
        }
      }
    }

    p,
    li {
      img {
        display: inline-block;
        width: ac(18px, 16px);
        height: ac(18px, 16px);
        object-fit: contain;
      }
    }
  }

  &__item-line {
    margin-top: auto;
    width: 100%;
    height: ac(15px, 8px);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    transform-origin: top;

    &:before,
    &:after {
      position: absolute;
      content: '';
      inset: 0;
      transition: opacity 0.6s ease;
    }

    &:before {
      opacity: 1;
      background: var(--gradient);
    }

    &:after {
      background: linear-gradient(-91deg, #8de8fc 0%, #7f3bb1 100%);
      opacity: 0;
      z-index: 1;
    }
  }

  @mixin media 551 {
    &__slider {
      max-width: 256px;
      margin-left: 0;
    }
  }
}
