.privacy-section {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(80px, 40px);

  &__wrap {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .content-element {
    a{
     &:hover{
       color: var(--cl-blue);
       text-decoration: none;
     }
    }
  }
}
