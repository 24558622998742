.footer {
  background: var(--cl-white);
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
  z-index: 10;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: ac(1156px, 956px);
    margin-left: auto;
    margin-right: auto;

    @mixin max-lg {
      width: 100%;
      max-width: 100%;
    }
  }

  &__top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: ac(30px, 31px);

    .line-decor {
      margin-bottom: 0;
    }
  }

  &__logo {
    @mixin aspect-ratio 160, 35;
    width: ac(160px, 147px);
    margin-right: ac(104px, 29px);

    img {
      object-fit: contain;
      object-position: left;
    }
  }

  &__text {
    width: 21.11%;
    margin-right: 20px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: ac(99px, 20px);

    &--pages {
      margin-right: ac(53px, 0px);
      padding-left: ac(53px, 20px);
    }
  }

  &__links-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: ac(11px, 10px);
  }

  &__link {
    font-family: var(--font-main);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color: var(--cl-black);
    transition: color 0.3s ease, text-decoration-color 0.3s ease;
    position: relative;

    &:before {
      content: '';
      bottom: 0;
      right: 0;
      height: 2px;
      background: var(--cl-black);
      transition: width 0.3s ease, background-color 0.3s ease,
        opacity 0.35s ease;
      position: absolute;
      opacity: 0;
      width: 0;
    }

    &:hover {
      color: var(--cl-dark-blue);

      &:before {
        right: auto;
        left: 0;
        opacity: 1;
        background-color: var(--cl-dark-blue);
        width: 100%;
      }
    }
  }

  &__links-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(25px, 36px);
    grid-row-gap: ac(12px, 16px);
  }

  &__link-page {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: ac(21px, 26px);
    position: relative;
    padding-left: calc(ac(21px, 26px) + ac(18px, 12px));
    transition: all 0.3s ease;

    &:hover {
      padding-left: 0;
      padding-right: calc(ac(21px, 26px) + ac(18px, 12px));
      .footer__link-page-text {
        color: var(--cl-dark-blue);

        &:before {
          right: auto;
          left: 0;
          opacity: 1;
          background-color: var(--cl-dark-blue);
          width: 100%;
          bottom: -2px;
        }
      }

      .footer__link-page-icon {
        background-color: var(--cl-dark-blue);
        color: var(--cl-blue);
        left: 100%;
        transform: translateY(-50%) translateX(-100%);
      }
    }
  }

  &__link-page-icon {
    width: ac(21px, 26px);
    height: ac(21px, 26px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ac(18px, 12px);
    border-radius: 6px 30px 30px 6px;
    background: var(--cl-black);
    transition: all 0.3s ease;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    i {
      color: var(--cl-white);
      font-size: ac(10px, 14px);
    }

    @mixin max-xs {
      margin-right: 8px;
      width: 22px;
      height: 22px;

      i {
        font-size: 12px;
      }
    }
  }

  &__link-page-text {
    font-family: var(--font-main);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: var(--cl-black);
    transition: color 0.3s ease, text-decoration-color 0.3s ease;
    position: relative;

    &:before {
      content: '';
      bottom: 0;
      right: 0;
      height: 2px;
      background: var(--cl-black);
      transition: width 0.3s ease, background-color 0.3s ease,
        opacity 0.35s ease;
      position: absolute;
      opacity: 0;
      width: 0;
    }

    @mixin media 374 {
      font-size: 16px;
    }

    @mixin max-xs {
      font-size: 14px;
    }
  }

  &__socials {
    margin-top: ac(-23px, -10px);
  }

  &__bottom {
    padding-top: 18px;
    margin-top: ac(47px, 32px);
    border-top: 1px solid var(--cl-black);
    width: 100%;
  }

  &__bottom-wrap {
    max-width: ac(1156px, 956px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @mixin max-lg {
      width: 100%;
      max-width: 100%;
    }
  }

  &__bottom-text {
    p {
      font-size: ac(14px, 13px);

      @mixin media 374 {
        font-size: 13px;
      }

      @mixin max-xs {
        font-size: 11px;
      }
    }
  }

  &__bottom-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: var(--font-main);
      font-size: ac(14px, 13px);
      margin-left: ac(8px, 4px);
      margin-right: ac(8px, 4px);
      color: var(--cl-black);

      @mixin media 374 {
        font-size: 13px;
        margin-left: 4px;
        margin-right: 4px;
      }

      @mixin max-xs {
        font-size: 11px;
        margin-left: 1px;
        margin-right: 1px;
      }
    }
  }

  &__bottom-link {
    font-size: ac(14px, 13px);
    font-family: var(--font-main);
    font-weight: 400;
    line-height: normal;
    color: var(--cl-black);
    transition: color 0.3s ease, text-decoration-color 0.3s ease;
    position: relative;

    &:before {
      content: '';
      bottom: 0;
      right: 0;
      height: 2px;
      background: var(--cl-black);
      transition: width 0.3s ease, background-color 0.3s ease,
        opacity 0.35s ease;
      position: absolute;
      opacity: 0;
      width: 0;
    }

    &:hover {
      color: var(--cl-dark-blue);

      &:before {
        right: auto;
        left: 0;
        opacity: 1;
        background-color: var(--cl-dark-blue);
        width: 100%;
      }
    }

    @mixin media 425 {
      letter-spacing: -0.5px;
    }

    @mixin media 374 {
      font-size: 13px;
    }

    @mixin max-xs {
      font-size: 11px;
    }
  }

  &__link-bold {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 150px;
    transform: translateX(-50%) translateY(-50%);
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
      display: none;
    }

    &:hover {
      transform: translateX(-50%) translateY(-50%) scale(1.1);
    }
  }

  @mixin media 1200 {
    &__links {
      margin-right: 20px;
    }
  }

  @mixin max-lg {
    &__socials {
      margin-top: 0;
    }

    &__links--pages {
      padding-top: 40px;
      width: 100%;
      padding-left: 0;
      order: 10;
      @mixin sm {
        align-items: center;
      }
    }

    &__links-grid {
      @mixin sm {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }

  @mixin max-md {
    &__text {
      width: 100%;
      margin-bottom: 20px;
    }

    &__link-bold {
      top: 225%;
    }
  }

  @mixin max-sm {
    &__links {
      width: 100%;
      margin-bottom: 20px;
    }

    &__socials {
      padding-top: 5px;
    }

    &__links--pages {
      padding-top: 32px;
    }

    &__bottom {
      border-top: 0;
      padding-top: 0;
    }

    &__bottom-wrap {
      padding-top: 28px;
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        background: var(--cl-black);
        width: 100%;
        content: '';
      }
    }

    &__link-bold {
      top: 150%;
    }
  }
}
