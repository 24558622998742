.first-bg-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 100vw;

  /*.first-bg-parallax {
      @mixin aspect-ratio-block 1440, 1232;
      position: absolute;
      overflow: visible;
      top: 0;
      left: 0;
      width: 100%;
    }*/

  .first-bg {
    @mixin aspect-ratio 1813, 1232;
    position: absolute;
    top: ac(-185px, -60px);
    right: -6.94%;
    width: 125.9%;
    clip-path: url('#logo-clip-path');
    min-width: 1178px;
    max-width: 1960px;

    .canvas-gradient {
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(1, -1);
      z-index: -1;

      @mixin media 768 {
        display: none;
      }
    }

    &__before {
      position: absolute;
      inset: 0;
      z-index: 1;
    }

    &.home-page {
      &:after {
        position: absolute;
        inset: 0;
        content: '';
        background: #00085f;
        mix-blend-mode: overlay;
        z-index: 2;
        background: radial-gradient(
          circle,
          rgb(0, 8, 95) 0%,
          rgb(127, 190, 225) 100%
        );
      }

      video {
        filter: grayscale(100%);
      }

      .first-bg__before {
        background: #80f9fa;
        mix-blend-mode: multiply;
        z-index: 1;

        &:after {
          position: absolute;
          left: 0;
          right: 0;
          height: 300px;
          bottom: 0;
          background: linear-gradient(
            0deg,
            rgb(4, 10, 35) 0%,
            rgba(106, 112, 171, 0) 100%
          );
          filter: blur(3px);
          content: '';
          z-index: 5;
          opacity: 0.3;
        }
      }
    }

    &.markets-page {
      /*background: linear-gradient(64deg, #80f9fa 6.66%, #00085f 95.93%);*/

      @mixin media 768 {
        &:after {
          position: absolute;
          inset: 0;
          background: linear-gradient(64deg, #80f9fa 6.66%, #00085f 95.93%);
          content: '';
        }
      }
    }

    &.contact-page,
    &.page-not-found {
      /*background: linear-gradient(64deg, #80f9fa 6.66%, #00085f 95.93%);*/

      @mixin media 768 {
        &:after {
          position: absolute;
          inset: 0;
          background: linear-gradient(64deg, #80f9fa 6.66%, #00085f 95.93%);
          content: '';
        }
      }
    }

    &.media-hub-page {
      clip-path: revert;

      img {
        max-width: revert;
        left: 50%;
        top: 50%;
        transform: translate(-48.5%, -60.5%);
      }
    }

    &.media-hub-article-page {
      clip-path: revert;

      img {
        max-width: revert;
        left: 50%;
        top: 50%;
        transform: translate(-48.5%, -60.5%);
      }
    }

    &.jobs-page,
    &.job-page {
      /*background: linear-gradient(220deg, #80f9fa 16.72%, #00000f 70.86%);*/
      top: ac(-314px, -100px);
      right: -8.94%;

      @mixin media 901 {
        right: auto;
        left: 50%;
      }

      @mixin media 768 {
        &:after {
          position: absolute;
          inset: 0;
          background: linear-gradient(220deg, #80f9fa 16.72%, #00000f 70.86%);
          content: '';
        }
      }
    }

    @mixin min-media 1600 {
      right: auto;
      left: 50%;
      transform: translateX(-55%);
    }

    @mixin media 901 {
      right: auto;
      left: 50%;
      transform: translateX(-44%);
    }

    &.about-page {
      img {
        object-position: 87% 50%;
      }

      &:after {
        position: absolute;
        inset: 0;
        content: '';
        background: #00085f;
        mix-blend-mode: overlay;
        z-index: 2;
      }

      .first-bg__before {
        background: #80f9fa;
        mix-blend-mode: multiply;
        z-index: 1;
      }

      video {
        filter: saturate(0);
      }
    }
  }
}

.first-section {
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding-bottom: ac(50px, 60px);

  &.markets-page {
    padding-bottom: 0;

    .first-section__wrap {
      /*@media (min-width: 1100px) {
              min-height: 116.3vh;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }*/
    }

    .first-section__item {
      background: rgba(245, 245, 245, 0.2);
      backdrop-filter: blur(10px);
    }

    .first-section__item-logo {
      border-color: var(--cl-white);

      i {
        color: var(--cl-white);
      }
    }

    .first-section__item-text {
      h2,
      h3,
      h4,
      p,
      li {
        color: var(--cl-white);
      }
    }

    .first-section__item-title {
      color: var(--cl-white);
    }
  }

  &.media-hub-page {
    padding-bottom: 0;

    .first-section__wrap {
      min-height: 0;
    }

    .first-section__title {
      h1 {
        letter-spacing: -0.6px;
      }
    }
  }

  &.media-hub-article-page {
    padding-bottom: 0;

    .first-section__title {
      max-width: 100%;
      margin-bottom: 60px;

      h1 {
        letter-spacing: -0.7px;
      }
    }
  }

  &-article-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: ac(50px, 25px);
    width: 100%;
    max-width: 1276px;

    &::before {
      content: '';
      display: block;
      padding-top: 39.18%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    padding-top: ac(302px, 140px);
    padding-bottom: ac(34px, 10px);

    @mixin min-media-height 1080 {
      min-height: 920px;
    }

    @mixin sm {
      @mixin media-height 850 {
        padding-top: ac(260px, 120px);
      }

      @mixin media-height 600 {
        min-height: 600px;
      }
    }

    @mixin media 1025 {
      @mixin md {
        min-height: 660px;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    margin: auto auto 30px;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: ac(42px, 20px) ac(36px, 20px);
    border-radius: ac(20px, 14px);
    background: rgba(245, 245, 245, 0.2);
    backdrop-filter: blur(10px);
  }

  &__item-title {
    width: 100%;
    max-width: ac(168px, 160px);
    margin-right: ac(48px, 24px);
    font-size: ac(23px, 20px);
    color: var(--cl-white);
  }

  &__item-text {
    flex: 1;

    h4 {
      font-size: ac(22px, 16px);
    }

    h2,
    h3,
    h4,
    p,
    li {
      color: var(--cl-white);
    }
  }

  &__item-logo {
    width: ac(65px, 46px);
    height: ac(65px, 46px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--cl-white);
    border-radius: 50%;
    margin-left: ac(43px, 24px);

    i {
      font-size: ac(26px, 18px);
      color: var(--cl-white);
    }
  }

  &__title {
    width: 100%;
    max-width: ac(950px, 520px);
    margin-bottom: 40px;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(32px, 40px);
    }
  }

  &__buttons {
  }

  &__additional-title {
    font-weight: 600;
    font-size: ac(30px, 20px);
    margin-bottom: 20px;
  }

  &.home-page,
  &.markets-page {
    .first-section__content {
      max-width: ac(916px, 600px);
    }
  }

  @mixin max-lg {
    @mixin sm {
      &__wrap {
        padding-top: 302px;
        min-height: auto;
      }

      &__title {
        margin-bottom: 84px;
      }
    }
  }

  @mixin max-sm {
    &__wrap {
      /*padding-top: 302px;*/
      /*min-height: auto;*/
      justify-content: flex-end;

      @mixin min-media-height 792 {
        min-height: 792px;
      }
    }

    &.media-hub-article-page {
      .first-section__wrap {
        min-height: auto;
        padding-top: 200px;
      }
    }

    &__content {
      margin-top: 0;
    }

    &__title {
      margin-bottom: 84px;
    }

    &__item {
      position: relative;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__item-title {
      width: 100%;
      max-width: 172px;
      order: 1;
      font-size: 18px;
    }

    &__item-text {
      padding-top: 16px;
      width: 100%;
      order: 3;
      flex: auto;
    }

    &__item-logo {
      order: 2;
    }
  }

  @mixin min-media 1500 {
    padding-bottom: 150px;
  }

  @mixin max-xs {
    &__item-title {
      max-width: 140px;
      font-size: 17px;
      margin-right: 10px;
    }
  }

  &.jobs-page {
    padding-bottom: 0;

    .first-section__wrap {
      min-height: 0;
    }

    .first-section__title {
      max-width: 100%;
      width: 100%;

      h1 {
        letter-spacing: -0.5px;
      }
    }
  }

  &.job-page {
    padding-bottom: 0;

    .first-section__wrap {
      min-height: 0;

      .btn {
        margin-top: 19px;
      }
    }

    .first-section__title {
      max-width: 100%;
      width: 100%;

      h1 {
        letter-spacing: -0.5px;
      }
    }
  }

  &.contact-page {
    padding-bottom: 0;

    .first-section__wrap {
      min-height: 0;
    }

    .first-section__title {
      max-width: 100%;
      width: 100%;
      margin-bottom: 40px;

      h1 {
        letter-spacing: -0.9px;
      }
    }

    .first-section__text {
      width: 100%;
      max-width: ac(628px, 600px);

      @mixin max-sm {
        max-width: 100%;
      }
    }
  }

  &.page-not-found {
    padding-top: 0;
    padding-bottom: 0;

    .first-section__title {
      max-width: 100%;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;

      h1 {
        letter-spacing: -0.9px;
      }
    }

    .first-section__wrap {
      min-height: 100vh;
      padding-top: 135px;
      padding-bottom: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @mixin media 1025 {
        @mixin min-media 770 {
          min-height: calc(100vh - 320px);
        }
      }
    }

    .first-section__text {
      text-align: center;
      width: 100%;
      max-width: ac(628px, 600px);

      @mixin max-sm {
        max-width: 100%;
      }

      &:not(:last-child) {
        margin-bottom: ac(60px, 34px);
      }
    }
  }

  &.about-page {
    .first-section__wrap {
      min-height: 100vh;
      justify-content: flex-end;
      padding-top: ac(160px, 140px);
      padding-bottom: ac(80px, 40px);
      @media (min-height: 1080px) {
        min-height: 1080px;
      }
    }

    .first-section__title {
      width: 100%;
      max-width: ac(1200px, 680px);
      margin-bottom: 40px;

      @mixin max-md {
        max-width: 100%;
      }
    }

    .first-section__content {
      max-width: 100%;
      width: 100%;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 0;
      margin-top: revert;

      @mixin max-sm {
        flex-direction: column;
        grid-row-gap: 20px;
      }
    }

    .first-section__item {
      width: 49.22%;
      background: rgba(245, 245, 245, 0.2);
      backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &-text {
        padding-top: 0;
      }

      @mixin max-sm {
        width: 100%;
      }
    }

    .first-section__item-top {
      width: 100%;
      margin-bottom: ac(30px, 24px);

      h3 {
        margin-right: ac(13px, 10px);
        max-width: 80%;
        width: auto;
      }

      .line-decor {
        margin-bottom: ac(12px, 10px);
      }
    }

    .first-section__item-title {
      order: unset;
    }

    .first-section__title {
      h1 {
        .word:last-child {
          @mixin xl {
            letter-spacing: -1px;
            transform: translateX(10px);
          }
        }
      }
    }

    .first-section__item-text {
      /* max-height: ac(226px, 152px);*/
      width: calc(100% + 10px);
      padding-right: 10px;
      --line-count: 8;
      --fz: ac(30px, 20px);
      --line-height: 1.2;
      line-height: var(--line-height);
      font-size: var(--fz);
      max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

      p {
        font-size: inherit;
        line-height: normal;
      }

      .simplebar-track.simplebar-vertical {
        width: 3px;
        background: var(--cl-dark-blue);
        border-radius: 4px;

        .simplebar-scrollbar {
          background: var(--cl-blue);
          border-radius: 4px;

          &:before {
            content: none;
          }
        }
      }
    }

    + .text-image-section {
      margin-top: ac(48px, 0px);
    }
  }
}

/*.is-safari {
  .first-section__video::after {
    mix-blend-mode: multiply;
  }
}*/
