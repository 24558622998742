/* ../../assets/fonts/ */

@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon.eot?okwij4');
  src: url('../../assets/fonts/icomoon.eot?okwij4#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?okwij4') format('truetype'),
    url('../../assets/fonts/icomoon.woff?okwij4') format('woff'),
    url('../../assets/fonts/icomoon.svg?okwij4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
  content: '\e90a';
}
.icon-close:before {
  content: '\e905';
}
.icon-search:before {
  content: '\e90e';
}
.icon-logo:before {
  content: '\e900';
}
.icon-arrow-down:before {
  content: '\e901';
}
.icon-arrow-left:before {
  content: '\e902';
}
.icon-arrow-right:before {
  content: '\e903';
}
.icon-arrow-up:before {
  content: '\e904';
}
.icon-facebook:before {
  content: '\e906';
}
.icon-filters:before {
  content: '\e907';
}
.icon-instagram:before {
  content: '\e908';
}
.icon-linkedin:before {
  content: '\e909';
}
.icon-plus:before {
  content: '\e90b';
}
.icon-plus-second:before {
  content: '\e90c';
}
.icon-quotes:before {
  content: '\e90d';
}
.icon-twitter:before {
  content: '\e90f';
}
