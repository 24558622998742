.careers-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(80px, 54px);
  overflow: visible;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  &__image {
    @mixin aspect-ratio 960, 523;
    width: ac(960px, 676px);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      position: absolute;
      inset: 0;
      content: '';
      z-index: 1;
      background: linear-gradient(0deg, #040000 1.53%, rgba(4, 0, 0, 0) 43.88%);
    }

    &[data-view='fade-l'] {
      transform: translateX(200px) translateY(-50%) translateZ(0);
    }

    &[data-view='fade-l'].animated {
      transform: translateX(0) translateY(-50%) translateZ(0);
    }

    @mixin min-media 1441 {
      left: -5%;
      width: calc(960px + 5%);
      &:before {
        position: relative;
        z-index: 4;
        background: linear-gradient(90deg, #040000 1.53%, rgba(4, 0, 0, 0) 15%);
      }
    }
  }

  &__wrap {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  &__content {
    width: 40.75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__content-aside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin sm {
      position: sticky;
      top: ac(140px, 120px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &.medium-size {
      p,
      li {
        font-size: ac(25px, 16px);
        @mixin max-sm {
          line-height: 1.5;
        }
      }
    }
  }

  &__jobs {
    margin-top: ac(76px, 40px);
    width: 49.22%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    gap: ac(20px, 10px);
  }

  @mixin max-sm {
    overflow: hidden;
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__content {
      width: 100%;
    }

    &__title {
      margin-top: -2px;
    }

    &__jobs {
      width: 100%;
    }

    &__image {
      top: 0;
      left: -52px;
      transform: translateY(0);

      &[data-view='fade-l'] {
        transform: translateX(50px) translateY(0) translateZ(0);
      }

      &[data-view='fade-l'].animated {
        transform: translateX(0) translateY(0) translateZ(0);
      }
    }
  }
}
