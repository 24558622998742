.article-section {
  padding: ac(66px, 40px) 0 ac(74px, 40px);
}
.article-content {
  max-width: 844px;
  margin: 0 auto;
  .content-element {
    h2,
    h3 {
      margin-top: ac(50px, 30px);
      margin-bottom: ac(27px, 20px);
      font-weight: 500;
    }
  }
  &-author {
    padding-top: ac(50px, 35px);
    h3 {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
.shared-links {
  padding-top: 19px;
  span {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .social-link {
    color: var(--cl-blue);
    &:hover {
      color: var(--cl-white);
    }
  }
}
