.contact-section {
  padding-top: ac(57px, 32px);
  padding-bottom: ac(160px, 60px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__form {
    width: 100%;
  }

  &__form-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(50px, 24px);

    @mixin max-sm {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-bottom {
    margin-top: ac(48px, 24px);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
