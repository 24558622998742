.roles-section {
  padding: ac(80px, 40px) 0 ac(140px, 60px);
  background: linear-gradient(179deg, #00000f 1.28%, #4cb9f2 126.08%);
  overflow: hidden;

  .title-container {
    margin-bottom: 18px;
    .title-animation {
      margin-right: ac(84px, 20px);
    }
  }
}
.roles-swiper-wr {
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
    display: flex;
    align-items: stretch;

    .role-card {
      width: 100%;
    }
  }
}
