.insights-card {
  display: inline-flex;
  flex-direction: column;
  max-width: 628px;
  width: 100%;

  &__thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: ac(50px, 25px) ac(50px, 25px) 0 0;
    clip-path: inset(0 0 0 0 round ac(50px, 25px) ac(50px, 25px) 0 0);
    transform: translateZ(0);

    &::before {
      content: '';
      display: block;
      padding-top: 58.91%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s;
    }
  }

  &-text {
    background: var(--cl-white);
    color: var(--cl-black);
    padding: 16px 23px 24px;
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    flex-direction: column;
  }

  &__title {
    font-size: ac(30px, 25px);
    font-weight: 500;
    margin-bottom: ac(34px, 22px);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex-grow: 1;
  }

  .btn {
    align-self: flex-start;
    margin-right: auto;
    width: fit-content;
  }

  &:hover {
    .insights-card__thumbnail {
      img {
        transform: scale(1.02);
      }
    }

    .btn__arrow {
      background: var(--cl-blue);
    }
  }
}
