.testimonials-slider {
  width: 100%;
  max-width: 100%;
  margin: 0;
  overflow: visible;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-quote {
    font-size: 28px;
    margin-bottom: ac(26px, 20px);
  }

  &__item-text {
    margin-bottom: 20px;
    width: 100%;
    max-height: ac(284px, 264px);

    .simplebar-track.simplebar-vertical {
      width: 2px;
      background: var(--cl-dark-blue);
      border-radius: 4px;
      transform: translateX(4px);

      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }
  }

  &__item-title,
  &__item-subtitle {
    font-size: ac(23px, 18px);
    line-height: normal;
  }

  &__item-title {
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: ac(8px, 10px);
    }
  }

  &__item-subtitle {
    font-weight: 500;
  }
}
