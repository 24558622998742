.markets-section {
  position: relative;
  overflow: hidden;
  z-index: 5;
  padding-top: ac(334px, 258px);
  padding-bottom: ac(138px, 60px);
  background: linear-gradient(179.22deg, #00000f 1.28%, #4cb9f2 126.08%);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title-container {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__title {
    margin-right: ac(30px, 26px);
  }

  &__slider {
    margin: 0;
    width: 100%;
    max-width: 100%;
    overflow: visible;

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: ac(20px, 12px);
        grid-row-gap: ac(20px, 12px);
      }

      .slider-buttons {
        display: none;
      }
    }
  }
}
