.about-us-section {
  padding-top: 350px;
  padding-bottom: ac(160px, 40px);
  overflow: hidden;
  z-index: 2;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
  }

  &__image {
    @mixin aspect-ratio 1640, 1011;
    position: absolute;
    width: ac(1640px, 1029px);
    top: -350px;
    left: ac(304px, 270px);
    z-index: -1;
    transform: rotate(135deg);
    mask-image: none;
    overflow: visible;

    @mixin media 552 {
      left: 27px;
      /*top: -290px;*/
    }

    img {
      animation: myAnimation 6s linear infinite;
    }

    @keyframes myAnimation {
      0% {
        transform: translate(0, 0) scale(1, 1);
        opacity: 1;
      }
      10% {
        transform: translate(0.15%, 0) scale(0.995, 0.98);
        opacity: 0.98;
      }
      25% {
        transform: translate(0, 0) scale(1, 1);
        opacity: 0.95;
      }
      35% {
        transform: translate(0, 0.15%) scale(1, 1.005);
        opacity: 0.95;
      }
      50% {
        transform: translate(0, 0) scale(1, 1);
        opacity: 1;
      }
      60% {
        transform: translate(0.15%, 0) scale(0.995, 0.98);
        opacity: 0.95;
      }
      75% {
        transform: translate(0, 0) scale(1, 1);
        opacity: 0.95;
      }
      85% {
        transform: translate(0, 0.15%) scale(1, 1.005);
        opacity: 0.98;
      }
      100% {
        transform: translate(0, 0) scale(1, 1);
        opacity: 1;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: ac(844px, 600px);
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(22px, 20px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }

    p,
    li {
      letter-spacing: 0;
    }
  }

  &__buttons {
    padding-top: ac(12px, 16px);
  }

  @mixin max-sm {
    &__content {
      max-width: 100%;
    }

    &__title {
      max-width: 250px;
    }
  }
}
