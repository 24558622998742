.what-section {
  padding-top: ac(75px, 32px);
  padding-bottom: ac(64px, 20px);
  overflow: hidden;

  &__wrap {
    padding-top: ac(67px, 32px);
    padding-left: ac(47px, 20px);
    padding-right: ac(47px, 20px);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 240px;
      background: var(--gradient);
      border-radius: 10px;
    }
  }

  &__content {
    background: var(--cl-black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(40px, 32px);
    padding-left: ac(61px, 28px);
    padding-right: ac(61px, 28px);
    padding-bottom: ac(40px, 20px);
    border-radius: 10px;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:nth-child(3) {
      max-width: 844px;
    }

    &.medium-size {
      p,
      li {
        font-size: ac(25px, 16px);
        @mixin max-sm {
          line-height: 1.5;
        }
      }
    }
  }

  &__buttons {
    padding-top: ac(16px, 12px);
  }

  &__image {
    @mixin aspect-ratio 1213, 809;
    position: absolute;
    right: 0;
    left: ac(-31px, -15px);
    top: ac(-255px, -123px);
    z-index: -1;
    transform: rotate(155.85deg);
    opacity: 0.3;
    min-width: 577px;
  }

  @mixin max-lg {
    &__text {
      &:nth-child(3) {
        max-width: 100%;
      }
    }
  }

  @mixin max-sm {
    &__image {
      right: auto;
    }
  }

  @mixin media 451 {
    &__wrap {
      padding-left: 0;
      padding-right: 0;

      &:before {
        left: -5.33vw;
        right: -5.33vw;
      }
    }
  }
}
