.text-video-section {
  padding-top: ac(134px, 60px);
  padding-bottom: ac(70px, 40px);
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title-container {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 10;

    &:not(:last-child) {
      margin-bottom: ac(40px, 31px);
    }

    /*.line-decor {
      @mixin md {
        margin-bottom: calc(((ac(50px, 30px) * 1.02) / 2) * 2.8);
      }
    }*/
  }

  &__title {
    max-width: ac(324px, 240px);
    margin-right: 10px;

    line-height: 1.2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 29%;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(27px, 30px);
    }
  }

  &__video {
    width: 66.14%;
    /*@mixin aspect-ratio 736, 432;*/
    clip-path: inset(0 0 0 0 round ac(50px, 16px));
    transform: translateZ(0);
    margin-top: ac(-125px, -90px);

    .plyr__video-wrapper {
      z-index: 0;
    }

    @mixin max-xs {
      clip-path: inset(0 0 0 0 round 10px);
    }
  }

  @mixin media 1300 {
    &__content {
      max-width: 34%;
    }

    &__video {
      width: 60%;
    }
  }

  @mixin max-xl {
    &__content {
      max-width: 34%;
    }

    &__video {
      width: 58%;
    }
  }

  @mixin max-lg {
    &__content {
      max-width: 39%;
    }
  }

  @mixin max-md {
    &__content {
      max-width: 100%;
    }

    &__title {
      max-width: 200px;
    }

    &__video {
      width: 100%;
      margin-top: 30px;
    }
  }

  + .text-image-section {
    margin-top: ac(42px, 20px);
  }
}
