.text-image-section {
  padding-top: ac(48px, 30px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title-container {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 10;

    &:not(:last-child) {
      margin-bottom: ac(26px, 31px);
    }
  }

  &__title {
    max-width: ac(412px, 300px);
    margin-right: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 32.29%;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(27px, 30px);
    }
  }

  &__image {
    width: 57.68%;
    @mixin aspect-ratio 736, 432;
    clip-path: inset(0 0 0 0 round ac(50px, 20px));
    transform: translateZ(0);
    margin-top: ac(-110px, -70px);
  }

  @mixin max-xl {
    &__content {
      max-width: 36%;
    }
  }

  @mixin max-lg {
    &__content {
      max-width: 39%;
    }
  }

  @mixin max-md {
    &__content {
      max-width: 100%;
    }

    &__image {
      width: 100%;
      margin-top: 30px;
    }
  }
}
