.recent-insights-section {
  overflow: hidden;
  padding: ac(80px, 40px) 0 ac(160px, 80px);
  .title-container {
    margin-bottom: 50px;
    .second-title-animation,
    .title-animation {
      margin-right: ac(50px, 25px);

      @mixin media 551 {
        max-width: 200px;
        margin-right: 10px;
      }
    }
  }
}
.recent-insights-swiper-wr {
  overflow: visible;
  width: 100%;
  max-width: 100%;

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    display: flex;
    height: revert;
    .insights-card {
    }
  }
}
