.role-card {
  padding: 30px 0 27px;
  border-bottom: 1px solid var(--cl-blue);
  width: 100%;
  max-width: 304px;
  margin: 0 auto;
  transition: all 0.3s ease;
  padding-right: ac(56px, 40px);
  overflow: hidden;
  position: relative;

  &__banner-container {
    position: absolute;
    top: 0;
    right: 0;
    width: ac(75px, 50px);
    height: ac(75px, 50px);
    z-index: 1;
  }

  &__banner {
    padding-top: ac(10px, 8px);
    padding-bottom: ac(10px, 8px);
    min-height: ac(34px, 30px);
    background: var(--cl-red);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    width: 200px;

    span {
      font-weight: 600;
      font-size: ac(14px, 12px);
      line-height: 128.57%;
      color: var(--cl-white);
    }
  }

  &-sector {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 7px;
  }
  &-title {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 4px;
  }
  &-sallary {
    font-weight: 400;
  }
  &:hover {
    color: var(--cl-blue);
    opacity: 1;
    border-color: var(--cl-dark-blue);
  }
}
