.values-section {
  padding: 80px 0 ac(80px, 120px);
  overflow: visible;

  .cont {
    display: flex;
    gap: 30px;
    align-items: flex-start;
  }

  .title-container {
    max-width: ac(403px, 270px, 1024, 1440);
    width: revert;
    flex: 1 0 auto;

    .second-title-animation {
      flex-shrink: 0;

      span {
        display: inline-flex;
        align-items: center;
        gap: 30px;
      }
    }

    .line-decor {
      min-width: ac(50px, 40px, 1024, 1440);
      /* margin-bottom: ac(-15px, -8px);*/

      &.animated::before {
        width: 150%;
      }
    }
  }
}

.values-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  flex: 1;
}

.values-card {
  --px: 20px;
  transition: 0.5s;
  position: relative;
  height: 184px;
  width: 100%;

  &-content-wr {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 20px;
    min-height: 100%;
    padding: 1px;
    width: 100%;
    background: linear-gradient(106deg, #f5f5f5, #f5f5f500);
    display: flex;

    &::after {
      content: '';
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      left: 1px;
      top: 1px;
      background: var(--cl-black);
      z-index: 1;
      border-radius: inherit;
    }

    div[data-simplebar] {
      max-height: 298px;
      min-height: 100%;
      width: 100%;
      z-index: 3;
      &.simplebar-scrollable-y{
        padding-right: 5px;
      }
    }
    .simplebar-wrapper {
      position: relative;
      z-index: 1;
    }
    .simplebar-scrollbar::before {
      background-color: var(--cl-white);
      color: var(--cl-white);
      opacity: 1 !important;
      left: 1px;
      right: 1px;
    }

    .simplebar-track.simplebar-vertical {
      background: rgba(255, 255, 255, 0.2) !important;
      opacity: 1 !important;
      width: 5px;
      border-radius: 5px;
      right: -8px;
      top: 20px;
      height: calc(100% - 40px);
    }
  }

  &-content {
    padding: 20px 10px 20px var(--px);
    position: relative;
    z-index: 2;
    overflow: hidden;
    width: 100%;
    &::after {
      content: '';
      display: block;
      background: linear-gradient(122deg, #00000f 0%, #4cb9f2 118.94%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      border-radius: 20px;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &-icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    border: 1px solid var(--cl-blue);
    transition: 0.3s;
    z-index: 1;
    margin-bottom: 20px;

    span {
      color: var(--cl-blue);
      font-size: 18px;
      opacity: 0;
      transition: opacity 0.3s;

      &:nth-of-type(2) {
        position: absolute;
        left: 50%;
        top: 55.5%;
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      i.icon-plus-second {
        font-size: ac(22px, 18px);
      }
    }
  }

  &-title {
    font-weight: 600;
    --line-count: 3;
    --fz: ac(30px, 20px);
    --line-height: 1.26;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

    .simplebar-scrollbar::before {
      background-color: var(--cl-white);
      color: var(--cl-white);
      opacity: 1 !important;
      left: 1px;
      right: 1px;
    }

    .simplebar-track.simplebar-vertical {
      background: rgba(255, 255, 255, 0.2) !important;
      opacity: 1 !important;
      width: 5px;
      border-radius: 5px;
      right: 0;
      height: 100%;
      top: 0;
    }
  }

  &-text {
    display: none;
    padding-top: 11px;
  }

  &-title,
  &-icon,
  &-text {
    position: relative;
    z-index: 1;
  }

  &.active {
    z-index: 3;

    .values-card {
      &-icon {
        span {
          &:nth-of-type(1) {
            opacity: 1;
          }

          &:nth-of-type(2) {
            opacity: 0;
          }
        }
      }

      &-content {
        &::after {
          opacity: 1;
        }
      }

      &-title {
        max-height: revert;
      }
    }
  }

  &:hover {
  }
}

@media (max-width: 992px) {
  .values-section .cont {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
  }

  .values-card-grid {
    width: 100%;
  }

  .values-section .title-container {
    max-width: 300px;
  }
}

@media (max-width: 650px) {
  .values-card-grid {
    grid-template-columns: 1fr;
  }

  .values-section .cont {
    align-items: flex-start;
  }
}
