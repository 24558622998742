.job-card {
  background: var(--cl-white);
  position: relative;
  z-index: 4;
  padding: ac(24px, 13px) ac(24px, 18px) ac(24px, 18px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: ac(20px, 14px);
  transition: all 0.35s ease;
  overflow: hidden;

  &__banner-container {
    position: absolute;
    top: 0;
    right: 0;
    width: ac(75px, 50px);
    height: ac(75px, 50px);
    z-index: 1;

    + .job-card__title {
      padding-top: 5px !important;
      padding-right: ac(40px, 20px) !important;
    }
  }

  &__banner {
    padding-top: ac(10px, 8px);
    padding-bottom: ac(10px, 8px);
    min-height: ac(34px, 30px);
    background: var(--cl-red);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    width: 200px;

    span {
      font-weight: 600;
      font-size: ac(14px, 12px);
      line-height: 128.57%;
      color: var(--cl-white);
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 6px;

    .job-card__title {
      width: 56.38%;
      @mixin max-line-length 2;
    }

    .job-card__list {
      width: 40.72%;
    }

    + .job-card__btn {
      margin-left: auto;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--cl-black);
    padding-right: ac(50px, 40px);
    width: 100%;
  }

  &__title {
    color: var(--cl-black);
    @mixin max-line-length 4;

    &:not(:last-child) {
      margin-bottom: ac(11px, 28px);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding-right: 20px;

    li {
      color: var(--cl-black);
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-black);
    border-radius: 6px 30px 30px 6px;
    cursor: pointer;
    width: ac(35px, 34px);
    height: ac(35px, 34px);
    transition: all 0.3s ease;

    i {
      transition: all 0.3s ease;
      color: var(--cl-white);
    }

    &:hover {
      background: var(--cl-dark-blue);

      i {
        color: var(--cl-blue);
      }
    }
  }

  &:hover {
    background: var(--cl-blue);
  }
}
