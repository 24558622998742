.choosing-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding-top: ac(100px, 60px);
  padding-bottom: ac(191px, 60px);

  &__bg {
    position: absolute;
    z-index: -1;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title-container {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: ac(80px, 40px);
    }
  }

  &__title {
    margin-right: ac(64px, 24px);
  }

  &__items {
    width: 49.22%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(20px, 18px);
    grid-row-gap: ac(49px, 26px);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .line-decor {
      height: ac(5px, 3px);
      width: 54.61%;

      &:not(:last-child) {
        margin-bottom: ac(20px, 19px);
      }

      &:before {
        background: var(--cl-white);
      }

      @mixin max-sm {
        width: 50%;
      }
    }
  }

  &__item-title {
    min-height: ac(76px, 60px);

    &:not(:last-child) {
      margin-bottom: ac(16px, 17px);
    }
  }

  &__item-text {
    width: 100%;
    padding-right: ac(0px, 6px);
    --line-count: 12;
    --fz: ac(18px, 16px);
    --line-height: 1.28;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    p {
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 0 !important;
      padding: 0;
    }

    .simplebar-track.simplebar-vertical {
      width: 3px;
      background: var(--cl-grey);
      border-radius: 4px;
      transform: translateX(ac(6px, 0px));

      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    @mixin media 1050 {
      --line-count: 10;
    }
  }

  &__content {
    padding-top: ac(14px, 38px);
    width: 48.75%;
    padding-left: ac(102px, 50px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__testimonials {
    .content-element {
      p {
        font-size: ac(22px, 16px);
      }
    }

    .testimonials-slider__item-text {
      max-height: ac(484px, 384px);
    }

    .testimonials-slider__item-quote {
      margin-left: ac(4px, 0px);
    }

    &:not(:last-child) {
      margin-bottom: ac(88px, 30px);
    }
  }

  &__buttons {
    margin-left: ac(-102px, -50px);
  }

  @mixin max-md {
    &__items {
      width: 100%;
    }

    &__content {
      width: 100%;
    }
  }

  @mixin media 551 {
    &__items {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &__content {
      padding-left: 0;
    }

    &__buttons {
      margin-left: 0;
    }

    &__title {
      max-width: 215px;
      margin-right: 5px;
    }
  }
}
