.market-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  clip-path: inset(0 0 0 0 round 20px);
  width: 100%;

  &__image {
    @mixin aspect-ratio 412, 190;
    width: 100%;
    clip-path: inset(0 0 0 0);

    img {
      transition: transform 0.3s ease;
    }
  }

  &__content {
    padding-top: ac(18px, 20px);
    padding-bottom: ac(24px, 20px);
    padding-left: ac(23px, 20px);
    padding-right: ac(23px, 20px);
    background: var(--cl-white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;

    .btn {
      margin-top: auto;
    }
  }

  &__title {
    color: var(--cl-black);
    &:not(:last-child) {
      margin-bottom: ac(40px, 30px);
    }

    @mixin max-sm {
      letter-spacing: -1px;
    }
  }

  &:hover {
    img {
      transform: scale(1.02);
    }

    .btn__arrow {
      background-color: var(--cl-blue);
    }
  }

  @mixin max-sm {
    &__image {
      @mixin aspect-ratio 335, 190;
    }
  }
}
