.text-section {
  padding-top: ac(48px, 30px);
  padding-bottom: ac(80px, 30px);
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title-container {
    display: flex;
    width: 100%;
    max-width: ac(844px, 600px);
    margin-left: ac(108px, 0px);

    &:not(:last-child) {
      margin-bottom: ac(26px, 31px);
    }
  }

  &__title {
    max-width: ac(346px, 200px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: ac(736px, 600px);
    margin-left: ac(216px, 0px);
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(27px, 30px);
    }
  }

  &.home-page {
    .line-decor.animated::before {
      width: 104.25%;
    }

    @mixin media 1050 {
      .line-decor.animated::before {
        width: 102%;
      }
    }

    @mixin max-lg {
      .line-decor.animated::before {
        width: 101%;
      }
    }

    @mixin max-sm {
      .line-decor.animated::before {
        width: 102%;
      }
    }

    @mixin max-sm {
      padding-top: 10px;
      margin-top: -30px;

      .line-decor.animated::before {
        width: 100%;
      }
    }

    @mixin media-height 820 {
      padding-top: 80px;
    }

    @mixin min-media 1500 {
      @mixin min-media-height 820 {
        padding-top: 120px;
      }
    }
  }

  &.markets-page {
    padding-top: ac(60px, 30px);
    .text-section__title {
      max-width: ac(476px, 220px);
    }

    .line-decor.animated::before {
      width: 112%;
    }
  }
}
