.job-search-section {
  padding-top: ac(5px, 20px);
  padding-bottom: ac(160px, 50px);
  overflow: visible;
  position: relative;
  z-index: 20;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: ac(54px, 44px);
  }

  &__label {
    text-transform: uppercase;
    margin-bottom: ac(28px, 24px);
    font-size: ac(20px, 18px);
    font-family: var(--font-main);
    font-weight: 600;
    color: var(--cl-white);
  }

  &__search {
    width: 100%;
    max-width: ac(1168px, 900px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .meta-input {
      width: 100%;
    }

    @mixin max-lg {
      max-width: 100%;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  &__sidebar {
    flex: 1;
    max-width: 30%;
  }

  &__sidebar-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(30px, 24px);
    /*border-bottom: ac(3px, 2px) solid var(--cl-dark-grey);*/
    position: relative;

    &:not(:last-child) {
      margin-bottom: ac(52px, 22px);
    }

    &.active {
      .job-search-section__sidebar-item-top {
        i {
          transform: scale(1, -1);
        }
      }

      .job-search-section__sidebar-item-content {
        opacity: 1;
        margin-top: 19px;
        max-height: 600px;
      }
    }

    .line-decor {
      position: absolute;
      left: 0;
      bottom: 0;
      height: ac(5px, 3px);
      width: ac(166px, 150px);

      &:before {
        background-color: var(--cl-white);
      }
    }
  }

  &__sidebar-item-top {
    cursor: default;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    i {
      font-size: 20px;
      color: var(--cl-grey);
      transition: transform 0.3s ease;
      transform: scale(1, 1);
    }
  }

  &__sidebar-item-title {
    font-size: ac(20px, 18px);
    line-height: normal;
    font-weight: 600;
    max-width: calc(100% - 40px);
    text-transform: uppercase;
  }

  &__sidebar-item-content {
    opacity: 0;
    margin-top: 0;
    transition: max-height 0.4s ease, margin-top 0.3s ease, opacity 0.3s ease;
    max-height: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 9px;
    overflow: hidden;
  }

  &__sidebar-item-subtitle {
    width: 100%;
    font-size: ac(18px, 17px);
    line-height: normal;
    font-weight: 500;

    &:not(:last-child) {
      padding-bottom: 5px;
    }
  }

  &__sidebar-item-additional {
    padding-top: 19px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 21px;
    grid-row-gap: 9px;
    margin-bottom: -2px;
  }

  &__result {
    width: 57.68%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: ac(108px, 0px);
    margin-top: ac(-2px, 0px);
  }

  &__result-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: ac(21px, 10px);

    @mixin min-media 941 {
      .job-card__list {
        margin-bottom: 35px;
      }

      .job-card__title {
        margin-bottom: 0;
      }

      .job-card__btn {
        position: absolute;
        bottom: ac(23px, 18px);
        right: ac(23px, 18px);
      }
    }

    @mixin media 940 {
      @mixin sm {
        .job-card__content {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .job-card__title,
          .job-card__list {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  &__filters-btn {
    display: none;
  }

  @mixin max-xl {
    &__sidebar {
      max-width: 33%;
    }
  }

  @mixin max-md {
    &__sidebar {
      max-width: 44%;
    }

    &__result {
      width: 50%;
    }
  }

  @mixin max-sm {
    overflow: hidden;

    &__sidebar {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      opacity: 0;
      max-width: 314px;
      z-index: 2;
      transition: all 0.4s ease;

      @mixin media 390 {
        max-width: 100%;
      }
    }

    &__result {
      width: 100%;
      transition: all 0.3s ease;
      transform: translateX(0);
      opacity: 1;
    }

    &__filters-btn {
      display: flex;
      margin-right: auto;
      margin-bottom: 24px;
    }

    &__container {
      &.active {
        .job-search-section__sidebar {
          position: relative;
          opacity: 1;
          transform: translateX(0);
        }

        .job-search-section__result {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateX(340px);
          opacity: 0.5;

          & > * {
            pointer-events: none;
          }

          @mixin media 390 {
            transform: translateX(110%);
          }
        }
      }
    }
  }
}
