.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-dark-blue) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-black);

  color: var(--cl-white);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-light-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-dark-blue);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1276px;
  width: perc(1276);

  @mixin max-lg {
    width: 89.33%;
  }

  @mixin max-xs {
    width: 91%;
  }
}

section,
header,
footer {
  position: relative;
  max-width: 100vw;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

.line-decor {
  height: 1px;
  position: relative;
  display: flex;
  width: 100%;

  &:before {
    content: '';
    background: var(--cl-blue);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: width 0.8s ease, background-color 0.25s ease, opacity 0.4s ease;
    transition-delay: 0.1s;
    opacity: 0;
    width: 0;
  }

  &.animated {
    /* width: 88px;*/

    &:before {
      width: 100%;
      opacity: 1;
    }
  }

  &.black-line {
    &:before {
      background-color: var(--cl-black);
    }
  }

  &.revert-line {
    transform: scale(-1, 1) !important;
  }
}

.subtitle {
  opacity: 0;
  &.animated {
    opacity: 1;
    .splitting .char {
      animation: slide-in 1s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(40ms * var(--char-index));
    }
  }
}

.title-animation {
  opacity: 0;
  &.animated {
    opacity: 1;
    .splitting .char {
      animation: slide-in-revert 0.7s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(4ms * var(--char-index));
    }
  }
}

.second-title-animation {
  transition: all 0.3s ease;
  opacity: 0;
  transform: scaleX(1.1) translateX(5%);
  transform-origin: top left;

  &.animated {
    opacity: 1;
    transform: scaleX(1) translateX(0);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(1em) rotate(-0.5deg) scale(0.5);
    opacity: 0;
  }
}

@keyframes slide-in-revert {
  from {
    transform: translateX(-1em) rotate(-0.5deg) scale(0.5);
    opacity: 0;
  }
}

@keyframes slide-in-revert {
  from {
    transform: translateX(0.6em) rotate(0deg) scale(0.9);
    opacity: 0;
  }
}

.title-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  .line-decor {
    display: inline-flex;
    flex: 1;
    margin-bottom: calc((ac(50px, 30px) * 1.02) / 2);
  }
}

.border-gradient-block {
  position: relative;
  z-index: 1;

  &:after {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background: var(--gradient);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    border-radius: inherit;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.grecaptcha-badge {
  z-index: 100;
}
