h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  color: var(--cl-white);
}

h1 {
  font-size: ac(90px, 44px);
  line-height: 1.11;
  font-weight: 400;
  letter-spacing: -2px;

  @mixin max-sm {
    line-height: 1.3;
    letter-spacing: -1px;
  }

  @mixin max-xs {
    font-size: 36px;
  }
}

h2 {
  font-size: ac(50px, 30px);
  line-height: normal;
  font-weight: 500;

  /*@mixin max-sm {
    line-height: 1.25;
  }*/

  &.small-size {
    font-size: ac(40px, 22px);
    line-height: 1.37;

    @mixin max-sm {
      line-height: 1.45;
    }
  }

  @mixin max-xs {
    font-size: 28px;
  }
}

h3 {
  font-size: ac(30px, 24px);
  line-height: normal;
  font-weight: 600;

  @mixin max-xs {
    font-size: 18px;
  }
}

h4 {
  font-size: ac(22px, 18px);
  line-height: normal;
  font-weight: 400;
}

h5 {
  font-size: ac(20px, 16px);
  line-height: normal;
  font-weight: 500;
}

h6 {
  font-size: ac(18px, 14px);
  line-height: normal;
  font-weight: 500;
}

p {
  font-size: ac(18px, 16px);
  font-weight: 400;

  line-height: normal;

  /*@mixin max-sm {
    line-height: 1.5;
  }*/

  /*strong {
    font-weight: 500;
  }*/
}

li {
  font-size: ac(18px, 16px);
  font-weight: 400;

  line-height: normal;

  /*@mixin max-sm {
    line-height: 1.5;
  }*/
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    a {
      font: inherit;
      display: inline-block;
      transition: all 0.31s ease;
      font-weight: 500;
      color: var(--cl-blue);
      text-decoration: underline;
      text-decoration-color: var(--cl-blue);

      &:hover {
        color: var(--cl-dark-grey);
        text-decoration-color: var(--cl-dark-grey);
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    li {
      padding-left: ac(27px, 18px);
      position: relative;

      &:before {
        content: '';
        width: 3px;
        height: 3px;
        position: absolute;
        left: calc(ac(27px, 18px) / 2);
        transform: translateX(-50%);
        top: 10px;
        background: var(--cl-white);
        border-radius: 50%;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(14px, 12px);
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    li {
      position: relative;
      padding-left: ac(27px, 18px);

      &:before {
        position: absolute;
        color: var(--cl-white);
        top: 0;
        left: ac(5px, 3px);
        font: inherit;
        content: counter(num) '.';
        counter-increment: num;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(14px, 12px);
    }
  }

  li,
  p {
    &:not(:last-child) {
      margin-bottom: ac(8px, 12px);
    }
  }

  h4 {
    &:not(:last-child) {
      margin-bottom: ac(12px, 12px);
    }
  }

  h2,
  h3 {
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }

    &:not(:first-child) {
      padding-top: ac(20px, 16px);
    }
  }

  h4 {
    &:not(:first-child) {
      padding-top: 15px;
    }
  }

  &.first-big-paragraph {
    p:first-child {
      font-size: ac(22px, 18px);
      line-height: normal;
      font-weight: 400;

      &:not(:last-child) {
        margin-bottom: ac(12px, 12px);
      }
    }
  }

  &.bigger-size {
    p {
      font-size: ac(30px, 20px);
    }
  }
}

.light-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-black);
  }
}

.bg-transparent-20 {
  background: rgba(245, 245, 245, 0.2) !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }
  .first-section__item-logo {
    border-color: var(--cl-white);
    i {
      color: var(--cl-white);
    }
  }
}

.subtitle {
  font-family: var(--font-main);
  color: var(--cl-white);
  font-weight: 600;
  font-size: ac(20px, 16px);
  line-height: normal;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: ac(40px, 12px);
  }
}
