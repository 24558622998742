.newsletter-section {
  margin-top: ac(-313px, -150px);
  padding-top: ac(417px, 200px);
  padding-bottom: ac(358px, 200px);
  margin-bottom: ac(-160px, -100px);
  overflow: hidden;
  z-index: 2;

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) calc(100% - 30px),
      rgba(0, 0, 0, 0) 100%
    );

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }

  &__content {
    width: 41.54%;
  }

  &__title {
    h2 {
      font-size: ac(70px, 40px);
      line-height: normal;
      font-weight: 500;
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__text {
  }

  &__form {
    width: 54.23%;
    padding-top: ac(123px, 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    .meta-input {
      width: 100%;
    }

    .btn {
      margin-top: ac(50px, 30px);
    }
  }

  &__thank {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    text-align: center;
    min-height: ac(230px, 174px);
  }

  @mixin media 901 {
    .newsletter-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__content {
        width: 100%;
        max-width: 500px;
      }

      &__form {
        width: 100%;
        padding-top: 40px;
      }

      &__thank {
        min-height: auto;
      }
    }
  }

  &.unsubscribe-page {
    margin-bottom: -100px;
    margin-top: 0;
    padding-top: ac(317px, 200px);

    .newsletter-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
      }

      &__content {
        width: 100%;
      }

      &__form {
        width: 100%;
        padding-top: ac(52px, 40px);
      }
    }
  }
}

/*.job-view-sections-container {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}*/
