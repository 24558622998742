.job-post-section {
  padding: 26px 0 ac(56px, 46px);
  overflow: visible;
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  &__container {
    width: 71.79%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__consultant {
    position: sticky;
    top: 85px;
    right: 0;
    max-width: 23.82%;
  }

  @mixin media 1200 {
    &__container {
      width: 68%;
    }

    &__consultant {
      max-width: 29%;
    }
  }

  @mixin media 901 {
    overflow: hidden;

    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__container {
      width: 100%;
      margin-bottom: 32px;
    }

    &__consultant {
      max-width: 330px;
      position: relative;
      top: auto;
      right: auto;
    }
  }

  @mixin media 451 {
    &__consultant {
      max-width: 100%;
    }
  }
}

.jobs-consultant {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 20px;
  background: rgba(245, 245, 245, 0.2);
  backdrop-filter: blur(10px);
  padding: ac(35px, 30px) ac(30px, 24px);
  width: 100%;

  &__title {
    color: var(--cl-white);
    text-align: center;
    font-size: ac(23px, 20px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    + .jobs-consultant__image {
      margin-top: ac(22px, 16px);
    }
  }

  &__subtitle {
    color: var(--cl-white);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:not(:last-child) {
      margin-bottom: ac(19px, 16px);
    }
  }

  &__image {
    @mixin aspect-ratio 1, 1;
    width: 100%;
    max-width: ac(196px, 172px);

    &:not(:last-child) {
      margin-bottom: ac(17px, 16px);
    }
  }

  &__link {
    color: var(--cl-white);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: color 0.3s ease;

    &:not(:last-child) {
      margin-bottom: ac(11px, 10px);
    }

    &:hover {
      color: var(--cl-blue);
    }
  }
}

.job-post-type {
  font-size: ac(30px, 25px);
  font-weight: 600;
  line-height: 1.26;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.job-post-card {
  width: 100%;
  max-width: 916px;
  background: var(--cl-white);
  padding: ac(42px, 32px) ac(36px, 26px);
  padding-right: ac(56px, 40px);
  border-radius: 20px;
  margin-bottom: 64px;
  overflow: hidden;
  position: relative;

  &__banner-container {
    position: absolute;
    top: 0;
    right: 0;
    width: ac(75px, 50px);
    height: ac(75px, 50px);
    z-index: 1;

    + .job-card__title {
      padding-top: 5px !important;
      padding-right: ac(40px, 20px) !important;
    }
  }

  &__banner {
    padding-top: ac(10px, 8px);
    padding-bottom: ac(10px, 8px);
    min-height: ac(34px, 30px);
    background: var(--cl-red);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    width: 200px;

    span {
      font-weight: 600;
      font-size: ac(14px, 12px);
      line-height: 128.57%;
      color: var(--cl-white);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: ac(102px, 20px);
    li {
      display: flex;
      flex-direction: column;
      color: var(--cl-black);

      &:last-of-type {
        padding-left: ac(24px, 0px);
      }
      span {
        font-weight: 400;
        font-size: 18px;
        display: block;
        margin-bottom: 10px;
      }
      div {
        font-size: 23px;
        font-weight: 600;
        line-height: 1.26;
      }
    }
  }
}
.job-post-content {
  max-width: 844px;
  .content-element {
    h2,
    h3 {
      margin-top: ac(50px, 30px);
    }
    h3 {
      font-weight: 500;
      margin-bottom: 20px;
    }
    ul,
    ol {
      padding-top: 8px;
      li {
        margin-bottom: 0 !important;
      }
    }
  }
  .btn {
    margin-top: ac(47px, 30px);
  }
  .shared-links {
    padding-top: ac(37px, 25px);
  }
}

@media (max-width: 750px) {
  .job-post-card {
    ul {
      flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }
}
